import * as emailv1 from 'proto/email/v1/email_pb'

import {
  canAcceptContract,
  canConfigureBookingAcceptedNotification,
  canConfigureBookingBookedNotification,
  canConfigureBookingCreatedNotification,
  canConfigureBookingEditedNotification,
  canConfigureBookingImportFailedNotification,
  canConfigureBookingQuoteNotification,
  canConfigureDocumentUploadedNotification,
  canConfigureInvoiceIssuedNotification,
  canConfigureInvoiceOverdueNotification,
  canConfigureMessageCreatedNotification,
  canConfigureOrderCreatedNotification,
  canConfigureOrderUpdatedNotification,
  canConfigureShipmentNotification,
} from '../../helpers/rolePermissions'

export const bookingFilterRelatedEmailTypes = [
  emailv1.Email.Type.BOOKING_CREATED,
  emailv1.Email.Type.BOOKING_UPDATED,
  emailv1.Email.Type.BOOKING_BOOKED,
  emailv1.Email.Type.BOOKING_ACCEPTED,
  emailv1.Email.Type.MESSAGE_CREATED,
  emailv1.Email.Type.DOCUMENT_UPLOADED,
]

export const emailTypeNames: {
  [key in emailv1.Email.Type]: string
} = {
  [emailv1.Email.Type.BOOKING_CREATED]: 'Booking created',
  [emailv1.Email.Type.BOOKING_UPDATED]: 'Booking edited',
  [emailv1.Email.Type.BOOKING_QUOTE]: 'Booking quote requested',
  [emailv1.Email.Type.BOOKING_BOOKED]: 'Booking booked',
  [emailv1.Email.Type.BOOKING_ACCEPTED]: 'Booking accepted',
  [emailv1.Email.Type.BOOKING_IMPORT_FAILED]: 'Booking import failed',
  [emailv1.Email.Type.ORDER_CREATED]: 'Order created',
  [emailv1.Email.Type.ORDER_UPDATED]: 'Order Updated',
  [emailv1.Email.Type.MESSAGE_CREATED]: 'Message posted',
  [emailv1.Email.Type.DOCUMENT_UPLOADED]: 'Document Uploaded',
  [emailv1.Email.Type.INVOICE_ISSUED]: 'Invoice Created',
  [emailv1.Email.Type.INVOICE_OVERDUE]: 'Invoice Overdue',
  [emailv1.Email.Type.SHIPMENT_CREATED]: 'Shipment Created',
  [emailv1.Email.Type.SHIPMENT_EDITED]: 'Shipment Edited',
  [emailv1.Email.Type.SHIPMENT_BOOKING_ADDED]: 'Shipment Booking Added',
  [emailv1.Email.Type.SHIPMENT_BOOKING_REMOVED]: 'Shipment Booking Removed',
  [emailv1.Email.Type.CONTRACT_ACCEPTED]: 'Contract Accepted',
}

export const emailTypePermissionCheck = {
  [emailv1.Email.Type.BOOKING_CREATED]: canConfigureBookingCreatedNotification,
  [emailv1.Email.Type.BOOKING_QUOTE]: canConfigureBookingQuoteNotification,
  [emailv1.Email.Type.BOOKING_UPDATED]: canConfigureBookingEditedNotification,
  [emailv1.Email.Type.BOOKING_BOOKED]: canConfigureBookingBookedNotification,
  [emailv1.Email.Type.BOOKING_ACCEPTED]: canConfigureBookingAcceptedNotification,
  [emailv1.Email.Type.BOOKING_IMPORT_FAILED]: canConfigureBookingImportFailedNotification,
  [emailv1.Email.Type.ORDER_CREATED]: canConfigureOrderCreatedNotification,
  [emailv1.Email.Type.ORDER_UPDATED]: canConfigureOrderUpdatedNotification,
  [emailv1.Email.Type.MESSAGE_CREATED]: canConfigureMessageCreatedNotification,
  [emailv1.Email.Type.DOCUMENT_UPLOADED]: canConfigureDocumentUploadedNotification,
  [emailv1.Email.Type.INVOICE_ISSUED]: canConfigureInvoiceIssuedNotification,
  [emailv1.Email.Type.INVOICE_OVERDUE]: canConfigureInvoiceOverdueNotification,
  [emailv1.Email.Type.SHIPMENT_CREATED]: canConfigureShipmentNotification,
  [emailv1.Email.Type.SHIPMENT_EDITED]: canConfigureShipmentNotification,
  [emailv1.Email.Type.SHIPMENT_BOOKING_ADDED]: canConfigureShipmentNotification,
  [emailv1.Email.Type.SHIPMENT_BOOKING_REMOVED]: canConfigureShipmentNotification,
  [emailv1.Email.Type.CONTRACT_ACCEPTED]: canAcceptContract,
}

export const notificationEmailTypes = [
  emailv1.Email.Type.BOOKING_IMPORT_FAILED,
  emailv1.Email.Type.ORDER_CREATED,
  emailv1.Email.Type.ORDER_UPDATED,
  // TODO move below types to shipment filters
  emailv1.Email.Type.SHIPMENT_CREATED,
  emailv1.Email.Type.SHIPMENT_EDITED,
  emailv1.Email.Type.SHIPMENT_BOOKING_ADDED,
  emailv1.Email.Type.SHIPMENT_BOOKING_REMOVED,
]
