import * as organizationv1 from 'proto/iam/v1/organization_pb'
import { Address } from 'proto/crm/v1/address_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const GET_REQ = 'swift2/organization/GET_REQ'
export const GET_RESP = 'swift2/organization/GET_RESP'
export const GET_ERR = 'swift2/organization/GET_ERR'
export const LIST_REQ = 'swift2/organizations/LIST_REQ'
export const LIST_RESP = 'swift2/organizations/LIST_RESP'
export const LIST_ERR = 'swift2/organizations/LIST_ERR'
export const CREATE_REQ = 'swift2/organizations/CREATE_REQ'
export const CREATE_RESP = 'swift2/organizations/CREATE_RESP'
export const CREATE_ERR = 'swift2/organizations/CREATE_ERR'
export const EDIT_REQ = 'swift2/organizations/EDIT_REQ'
export const EDIT_RESP = 'swift2/organizations/EDIT_RESP'
export const EDIT_ERR = 'swift2/organizations/EDIT_ERR'
export const EDIT_INV_REQ = 'swift2/organizations/EDIT_INV_REQ'
export const EDIT_INV_RESP = 'swift2/organizations/EDIT_INV_RESP'
export const EDIT_INV_ERR = 'swift2/organizations/EDIT_INV_ERR'
export const EDIT_INSTRUCTIONS_REQ = 'swift2/organizations/EDIT_INSTRUCTIONS_REQ'
export const EDIT_INSTRUCTIONS_RESP = 'swift2/organizations/EDIT_INSTRUCTIONS_RESP'
export const EDIT_INSTRUCTIONS_ERR = 'swift2/organizations/EDIT_INSTRUCTIONS_ERR'
export const SET_CURRENT_ORG = 'swift2/organization/SET_CURRENT_ORG'

export const Actions = {
  listOrganizationsReq: (skip: number, limit: number) => createAction(LIST_REQ, { skip, limit }),
  listOrganizationsResp: (organizations: Array<organizationv1.Organization>) =>
    createAction(LIST_RESP, { organizations }),
  listOrganizationsErr: (err: Error) => createAction(LIST_ERR, { err }),
  getOrganizationReq: (id?: number) => createAction(GET_REQ, { id }),
  getOrganizationResp: (organization?: organizationv1.Organization) =>
    createAction(GET_RESP, { organization }),
  getOrganizationErr: (err: Error) => createAction(GET_ERR, { err }),
  createOrganizationReq: (organization: organizationv1.Organization) =>
    createAction(CREATE_REQ, { organization }),
  createOrganizationResp: (organization: organizationv1.Organization) =>
    createAction(CREATE_RESP, { organization }),
  createOrganizationErr: (err: Error) => createAction(CREATE_ERR, { err }),
  editOrganizationReq: (organization: organizationv1.Organization) =>
    createAction(EDIT_REQ, { organization }),
  editOrganizationResp: (organization: organizationv1.Organization) =>
    createAction(EDIT_RESP, { organization }),
  editOrganizationErr: (err: Error) => createAction(EDIT_ERR, { err }),
  editInvoicingReq: (
    id: number,
    invoiceConfig: organizationv1.InvoiceConfig,
    invoiceAddress: Address,
  ) => createAction(EDIT_INV_REQ, { id, invoiceConfig, invoiceAddress }),
  editInvoicingResp: (organization: organizationv1.Organization) =>
    createAction(EDIT_INV_RESP, { organization }),
  editInvoicingErr: (err: Error) => createAction(EDIT_INV_ERR, { err }),
  editInstructionsReq: (
    id: number,
    externalInstructions: string,
    internalOperationalInstructions: string,
    internalFinancialInstructions: string,
  ) =>
    createAction(EDIT_INSTRUCTIONS_REQ, {
      id,
      externalInstructions,
      internalOperationalInstructions,
      internalFinancialInstructions,
    }),
  editInstructionsResp: (organization: organizationv1.Organization) =>
    createAction(EDIT_INSTRUCTIONS_RESP, { organization }),
  editInstructionsErr: (err: Error) => createAction(EDIT_INSTRUCTIONS_ERR, { err }),
  setCurrentOrganization: (organization?: organizationv1.Organization) =>
    createAction(SET_CURRENT_ORG, { organization }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
