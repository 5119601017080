import * as grpcWeb from 'grpc-web'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { UserGroupServicePromiseClient } from 'proto/iam/v1/usergroup_grpc_web_pb'
import * as usergroupv1 from 'proto/iam/v1/usergroup_pb'

import { Actions, CREATE_REQ, GET_REQ, LIST_REQ } from '../../store/iam/usergroup/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: UserGroupServicePromiseClient,
  action: ReturnType<typeof Actions.listUserGroupsReq>,
) {
  try {
    const { limit, skip } = action.payload
    const req = new usergroupv1.ListUserGroupsRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    const resp: usergroupv1.ListUserGroupsResponse = yield call(
      [client, client.listUserGroups],
      req,
      authMetadata(),
    )
    yield put(Actions.listUserGroupsResp(resp.getTotalCount(), resp.getUserGroupsList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listUserGroupsErr(err))
  }
}

export function* get(
  client: UserGroupServicePromiseClient,
  action: ReturnType<typeof Actions.getUserGroupReq>,
) {
  try {
    const { id } = action.payload
    const req = new usergroupv1.GetUserGroupRequest()
    req.setUserGroupId(id)
    const resp: usergroupv1.GetUserGroupResponse = yield call(
      [client, client.getUserGroup],
      req,
      authMetadata(),
    )
    yield put(Actions.getUserGroupResp(resp.getUserGroup()))
  } catch (err) {
    if (
      (err as any).code &&
      ((err as any).code === grpcWeb.StatusCode.NOT_FOUND || grpcWeb.StatusCode.PERMISSION_DENIED)
    ) {
      yield put(Actions.getUserGroupResp(undefined))
    } else {
      if (err instanceof Error) yield put(Actions.getUserGroupErr(err))
    }
  }
}

export function* create(
  client: UserGroupServicePromiseClient,
  action: ReturnType<typeof Actions.createUserGroupReq>,
) {
  try {
    const { userGroup } = action.payload
    const req = new usergroupv1.CreateUserGroupRequest()
    req.setUserGroup(userGroup)
    const resp: usergroupv1.CreateUserGroupResponse = yield call(
      [client, client.createUserGroup],
      req,
      authMetadata(),
    )
    const newUserGroup = resp.getUserGroup()
    if (!newUserGroup) {
      throw new Error('missing user group')
    }
    yield put(Actions.createUserGroupResp(newUserGroup))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createUserGroupErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.userGroup)
  yield takeLatest(GET_REQ, get, clients.userGroup)
  yield takeEvery(CREATE_REQ, create, clients.userGroup)
}
