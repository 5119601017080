import { all, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as documentv1 from 'proto/document/v1/document_pb'
import * as userv1 from 'proto/iam/v1/user_pb'
import * as invoicev1 from 'proto/invoicing/v1/invoice_pb'
import * as orderv1 from 'proto/order/v1/order_pb'
import * as shipmentv1 from 'proto/shipment/v1/shipment_pb'

import {
  sortStoryItems,
  storyBookingAcception,
  storyBookingCreation,
  storyBookingEvent,
  storyDocument,
  storyInvoiceIssued,
  storyMessage,
  storyOrderCreation,
  storyOrderEvent,
  storyShipmentCreation,
} from '../../components/Story/storyUtils'
import {
  Actions as BookingActions,
  GET_EVENTS_ERR as GET_BOOKING_EVENTS_ERR,
  GET_EVENTS_RESP as GET_BOOKING_EVENTS_RESP,
} from '../../store/booking/booking/actions'
import { getItemByRef as getBookingByRef } from '../../store/booking/booking/reducer'
import { getItemsByFilter as getDocuments } from '../../store/document/reducer'
import { getItemsByBookingRef as getInvoicesByBookingRef } from '../../store/invoicing/invoice/reducer'
import {
  LIST_ERROR as LIST_MESSAGE_ERR,
  LIST_RESPONSE as LIST_MESSAGE_RESP,
  Actions as MessageActions,
} from '../../store/message/actions'
import {
  GET_EVENTS_ERR as GET_ORDER_EVENTS_ERR,
  GET_EVENTS_RESP as GET_ORDER_EVENTS_RESP,
  Actions as OrderActions,
} from '../../store/order/order/actions'
import { getItemByRef as getOrderByRef } from '../../store/order/order/reducer'
import { RootState } from '../../store/reducer'
import { getItemByRef as getShipmentByRef } from '../../store/shipment/shipment/reducer'
import {
  Actions,
  GET_ALL_ITEMS,
  GET_STORY_USERS_ERR,
  GET_STORY_USERS_RESP,
  UPDATE_DOCUMENTS,
  UPDATE_INVOICES,
  UPDATE_MESSAGES,
} from '../../store/story/actions'
import { getCurrentReferences, getItems } from '../../store/story/reducer'

import {
  StoryBookingEvent,
  StoryDocument,
  StoryItem,
  StoryMessage,
  StoryOrderEvent,
} from '../../components/Story/types'

import { filterRepeatedDocuments } from '../../helpers/documents'

export function* updateStoryMessages() {
  const { bookingRef, orderRef, shipmentRef } = yield select(getCurrentReferences)
  const messages: StoryMessage[] = yield setMessages(bookingRef, orderRef, shipmentRef)

  const currentStoryItems: StoryItem[] = yield select(getItems)
  const newStoryItems: StoryItem[] = currentStoryItems.filter((item) => item.tag !== 'MESSAGE')
  newStoryItems.push(...messages)
  const sortedItems = sortStoryItems(newStoryItems)
  yield listStoryUsers(newStoryItems)
  yield put(Actions.setStoryItems(sortedItems))
}

export function* updateStoryDocuments(action: ReturnType<typeof Actions.updateStoryDocuments>) {
  const { newDocuments } = action.payload
  const currentStoryItems: StoryItem[] = yield select(getItems)
  const { bookingRef, orderRef, shipmentRef } = yield select(getCurrentReferences)
  const currentAmountDocuments = currentStoryItems.filter((item) => item.tag === 'DOCUMENT').length

  // Only run on triggered events not on initial load (getStoryItem)
  if (currentStoryItems.length > 0 && (!newDocuments || currentAmountDocuments < newDocuments)) {
    const documents: StoryDocument[] = yield setDocuments(bookingRef, orderRef, shipmentRef)
    const newStoryItems: StoryItem[] = currentStoryItems.filter((item) => item.tag !== 'DOCUMENT')
    newStoryItems.push(...documents)
    const sortedItems = sortStoryItems(newStoryItems)
    yield listStoryUsers(newStoryItems)
    yield put(Actions.setStoryItems(sortedItems))
  } else if (currentStoryItems.length === 0 && (!!bookingRef || !!orderRef || !!shipmentRef)) {
    yield put(Actions.getAllStoryItems(bookingRef, orderRef, shipmentRef))
  } else {
    yield put(Actions.setIsFetching(false))
  }
}

export function* updateStoryInvoices(action: ReturnType<typeof Actions.updateStoryInvoices>) {
  const { invoice } = action.payload
  const newStoryItems: StoryItem[] = yield select(getItems)
  newStoryItems.push(storyInvoiceIssued(invoice))
  const sortedItems = sortStoryItems(newStoryItems)
  yield listStoryUsers(newStoryItems)
  yield put(Actions.setStoryItems(sortedItems))
}

export function* getStoryItems(action: ReturnType<typeof Actions.getAllStoryItems>) {
  const { bookingRef = '', orderRef = '', shipmentRef = '' } = action.payload
  if (!bookingRef && !orderRef && !shipmentRef) {
    yield put(Actions.setStoryItemError())
    return
  } else {
    yield put(Actions.setReferences(bookingRef, orderRef, shipmentRef))
  }

  try {
    const {
      messages,
      documents,
      bookingEvents,
      orderEvents,
      orderCreation,
      bookingCreation,
      bookingAcception,
      shipmentCreation,
      invoiceIssued,
    } = yield all({
      messages: setMessages(bookingRef, orderRef, shipmentRef),
      documents: setDocuments(bookingRef, orderRef, shipmentRef),
      bookingEvents: setBookingEvents(bookingRef),
      orderEvents: setOrderEvents(orderRef),
      orderCreation: getOrderCreation(orderRef),
      bookingCreation: getBookingCreation(bookingRef),
      bookingAcception: getBookingAcception(bookingRef),
      shipmentCreation: getShipmentCreation(shipmentRef),
      invoiceIssued: getInvoiceIssued(bookingRef),
    })

    const storyItems: StoryItem[] = [
      ...messages,
      ...documents,
      ...bookingEvents,
      ...orderEvents,
      orderCreation,
      bookingCreation,
      bookingAcception,
      shipmentCreation,
      ...invoiceIssued,
    ].filter((item) => !!item)

    yield listStoryUsers(storyItems)

    const sortedItems = sortStoryItems(storyItems)
    yield put(Actions.setStoryItems(sortedItems))
  } catch {
    yield put(Actions.setStoryItemError())
  }
}

function* getOrderCreation(orderRef: string) {
  const order: orderv1.Order = yield select((state: RootState) => getOrderByRef(state, orderRef))
  if (order) {
    return storyOrderCreation(order)
  }
  return undefined
}

function* getBookingCreation(bookingRef: string) {
  const booking: bookingv1.Booking = yield select((state: RootState) =>
    getBookingByRef(state, bookingRef),
  )
  if (booking) {
    return storyBookingCreation(booking)
  }
  return undefined
}

function* getBookingAcception(bookingRef: string) {
  const booking: bookingv1.Booking = yield select((state: RootState) =>
    getBookingByRef(state, bookingRef),
  )
  if (
    booking &&
    booking.getAcceptor()?.getUserId() &&
    booking.getStatus() >= bookingv1.Booking.Status.ACCEPTED
  ) {
    return storyBookingAcception(booking)
  }
  return undefined
}

function* getShipmentCreation(shipmentRef: string) {
  const shipment: shipmentv1.Shipment = yield select((state: RootState) =>
    getShipmentByRef(state, shipmentRef),
  )
  if (shipment) {
    return storyShipmentCreation(shipment)
  }
  return undefined
}

function* getInvoiceIssued(bookingRef: string) {
  const invoices: invoicev1.Invoice[] = yield select((state: RootState) =>
    getInvoicesByBookingRef(state, bookingRef),
  )
  return invoices
    .filter((i) => i.getStatus() === invoicev1.Invoice.Status.ISSUED)
    .map(storyInvoiceIssued)
}

function* setMessages(bookingRef: string, orderRef: string, shipmentRef: string) {
  let messages: StoryMessage[] = []

  yield put(MessageActions.list(orderRef || '', bookingRef || '', shipmentRef || ''))
  const { fetchErr, fetchResp } = yield race({
    fetchErr: take(LIST_MESSAGE_ERR),
    fetchResp: take(LIST_MESSAGE_RESP),
  })
  if (!fetchErr) {
    messages = fetchResp.payload.messages.map(storyMessage)
  }

  return messages
}

function* setDocuments(bookingRef: string, orderRef: string, shipmentRef: string) {
  let documents: documentv1.Document[] = []
  documents = yield select((state: RootState) =>
    getDocuments(state, {
      orderRef: orderRef ? [orderRef] : undefined,
      bookingRef: bookingRef ? [bookingRef] : undefined,
      shipmentRef: shipmentRef ? [shipmentRef] : undefined,
    }),
  )
  const storyDocuments = filterRepeatedDocuments(documents, [
    documentv1.Usage.CMR,
    documentv1.Usage.TRANSPORTORDER,
    documentv1.Usage.BOOKINGCONFIRMATION,
    documentv1.Usage.SHIPMENTADVICE,
  ]).map(storyDocument)

  return storyDocuments
}

function* setBookingEvents(bookingRef: string) {
  let bookingEvents: StoryBookingEvent[] = []

  if (bookingRef) {
    yield put(BookingActions.getEventsReq(bookingRef))
    const { fetchErr, fetchResp } = yield race({
      fetchErr: take(GET_BOOKING_EVENTS_ERR),
      fetchResp: take(GET_BOOKING_EVENTS_RESP),
    })

    if (!fetchErr) {
      bookingEvents = fetchResp.payload.events.map(storyBookingEvent)
    }
  }

  return bookingEvents
}

function* setOrderEvents(orderRef: string) {
  let orderEvents: StoryOrderEvent[] = []

  if (orderRef) {
    yield put(OrderActions.getOrderEventsReq(orderRef))
    const { fetchErr, fetchResp } = yield race({
      fetchErr: take(GET_ORDER_EVENTS_ERR),
      fetchResp: take(GET_ORDER_EVENTS_RESP),
    })

    if (!fetchErr) {
      orderEvents = fetchResp.payload.events.map(storyOrderEvent)
    }
  }

  return orderEvents
}

function* listStoryUsers(storyItems: StoryItem[]) {
  let users: { [key: string]: userv1.User } = {}
  const uniqueOwners = storyItems
    .filter((item) => !!item.owner)
    .map((item) => item.owner)
    .filter((value, index, self) => self.indexOf(value) === index)

  yield put(Actions.getStoryUsersReq(uniqueOwners))

  // Does this so that sagas treats the generator function as a promise and waits for the response
  const { fetchErr, fetchResp } = yield race({
    fetchErr: take(GET_STORY_USERS_ERR),
    fetchResp: take(GET_STORY_USERS_RESP),
  })
  if (!fetchErr) {
    users = fetchResp.payload.users
  }

  return users
}

export default function* sagas() {
  yield takeLatest(GET_ALL_ITEMS, getStoryItems)
  yield takeEvery(UPDATE_MESSAGES, updateStoryMessages)
  yield takeLatest(UPDATE_DOCUMENTS, updateStoryDocuments)
  yield takeLatest(UPDATE_INVOICES, updateStoryInvoices)
}
