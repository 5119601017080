import * as userv1 from 'proto/iam/v1/user_pb'

export const roleTypes: ReadonlyArray<userv1.User.Role> = [
  userv1.User.Role.CONTROLLER,
  userv1.User.Role.ORGANIZATION,
  userv1.User.Role.RECEIVER,
  userv1.User.Role.SHIPPER,
  userv1.User.Role.TRANSPORTER,
  userv1.User.Role.FINANCE,
  userv1.User.Role.MANAGER,
  userv1.User.Role.ADMIN,
  userv1.User.Role.SALES,
  userv1.User.Role.API,
]

export const roles: { [key in userv1.User.Role]: string } = {
  [userv1.User.Role.CONTROLLER]: 'Controller',
  [userv1.User.Role.ORGANIZATION]: 'Organization',
  [userv1.User.Role.RECEIVER]: 'Receiver',
  [userv1.User.Role.SHIPPER]: 'Shipper',
  [userv1.User.Role.TRANSPORTER]: 'Transporter',
  [userv1.User.Role.FINANCE]: 'Finance',
  [userv1.User.Role.MANAGER]: 'Manager',
  [userv1.User.Role.ADMIN]: 'Admin',
  [userv1.User.Role.SALES]: 'Sales',
  [userv1.User.Role.API]: 'API',
}

export const userStatusTypes: ReadonlyArray<userv1.User.UserStatus> = [
  userv1.User.UserStatus.ACTIVE,
  userv1.User.UserStatus.INACTIVE,
  userv1.User.UserStatus.DISABLED,
]

export const userStatuses: { [key in userv1.User.UserStatus]: string } = {
  [userv1.User.UserStatus.ACTIVE]: 'Active',
  [userv1.User.UserStatus.INACTIVE]: 'Inactive',
  [userv1.User.UserStatus.DISABLED]: 'Disabled',
}

export const haveRole = (role: userv1.User.Role, user?: userv1.User): boolean =>
  user ? user.getRolesList().includes(role) : false

export const containRole = (roles: userv1.User.Role[], userRoles: userv1.User.Role[]): boolean =>
  userRoles.some((v) => roles.includes(v))
