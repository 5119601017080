import * as invoicev1 from 'proto/invoicing/v1/invoice_pb'

import { Pagination, defaultPageSize } from './query'

export interface InvoicePage {
  pagination: Pagination
  filter: InvoiceFilter
  sorting: invoicev1.InvoiceSorting
}

export interface InvoiceFilter {
  bookingRef?: Array<string>
  search?: string
  status?: Array<invoicev1.Invoice.Status>
  organizationId?: Array<number>
  branchId?: Array<number>
  category?: Array<invoicev1.Invoice.Category>
  partOf?: invoicev1.InvoiceFilter.PartOf
  autoInvoicing?: boolean
}

export const getDefaultInvoicePage = (): InvoicePage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new invoicev1.InvoiceSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
export type ParsingInvoicePage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
}
