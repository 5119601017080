import { call, put, takeEvery } from 'redux-saga/effects'
import { INTERNAL_ORGANIZATION_ID } from 'shared/constants/organization.constant'

import * as commonOwner from 'proto/common/owner_pb'
import { MessageServicePromiseClient } from 'proto/messaging/v1/messaging_grpc_web_pb'
import * as messagev1 from 'proto/messaging/v1/messaging_pb'

import {
  Actions,
  LIST_REQUEST,
  SEND_MESSAGE,
  SET_PINNED_MESSAGE_REQUEST,
} from '../../store/message/actions'
import { Actions as StoryActions } from '../../store/story/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: MessageServicePromiseClient,
  action: ReturnType<typeof Actions.list>,
) {
  try {
    const { orderRef, bookingRef, shipmentRef } = action.payload
    const req = new messagev1.ListMessagesRequest()
    req.setOrderRef(orderRef)
    req.setBookingRef(bookingRef)
    req.setShipmentRef(shipmentRef)
    const resp: messagev1.ListMessagesResponse = yield call(
      [client, client.listMessages],
      req,
      authMetadata(),
    )
    const messages = resp.getMessagesList()
    const count = resp.getCount()
    yield put(Actions.listResp(messages, count))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listErr(err))
  }
}

export function* setPinnedMessage(
  client: MessageServicePromiseClient,
  action: ReturnType<typeof Actions.setPinnedReq>,
) {
  try {
    const { id, isPinned } = action.payload
    const req = new messagev1.SetPinnedMessageRequest()
    req.setPinned(isPinned)
    req.setMessageId(id)

    const resp: messagev1.SetPinnedMessageResponse = yield call(
      [client, client.setPinnedMessage],
      req,
      authMetadata(),
    )
    const result = resp.getMessage()
    if (!result) {
      throw new Error('Response contained no message')
    }
    yield put(Actions.setPinnedResp(result))
    yield put(StoryActions.updateStoryMessages())
  } catch (err) {
    if (err instanceof Error) yield put(Actions.setPinnedErr(err))
  }
}

export function* sendMessage(
  client: MessageServicePromiseClient,
  action: ReturnType<typeof Actions.sendMessage>,
) {
  try {
    const { text, orderRef, bookingRef, shipmentRef, relatedOwner, internal } = action.payload
    const req = new messagev1.CreateMessageRequest()
    const message = new messagev1.Message()
    const owner = new commonOwner.Owner()
    owner.setOrganizationId(internal ? INTERNAL_ORGANIZATION_ID : relatedOwner.getOrganizationId())
    owner.setUserGroupId(relatedOwner.getUserGroupId())
    message.setShipmentRef(shipmentRef)
    message.setOrderRef(orderRef)
    message.setBookingRef(bookingRef)
    message.setText(text)
    message.setOwner(owner)
    req.setMessage(message)

    const resp: messagev1.CreateMessageResponse = yield call(
      [client, client.createMessage],
      req,
      authMetadata(),
    )
    const result = resp.getMessage()
    if (!result) {
      throw new Error('Response contained no message')
    }
    yield put(Actions.sendMessageSuccess(result))
    yield put(StoryActions.updateStoryMessages())
  } catch (err) {
    if (err instanceof Error) yield put(Actions.sendMessageError(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeEvery(LIST_REQUEST, list, clients.message)
  yield takeEvery(SET_PINNED_MESSAGE_REQUEST, setPinnedMessage, clients.message)
  yield takeEvery(SEND_MESSAGE, sendMessage, clients.message)
}
