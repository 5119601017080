import { call, put, takeLatest } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { FilterServicePromiseClient } from 'proto/filtering/v1/filter_grpc_web_pb'
import * as filteringv1 from 'proto/filtering/v1/filter_pb'
import * as userv1 from 'proto/iam/v1/user_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_REQ,
} from '../../store/filter/actions'
import { Actions as UserActions } from '../../store/iam/user/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { bookingFilterRelatedEmailTypes } from '../../views/Profile/consts'

import { GRPCClients } from '../clients'

export function* list(
  client: FilterServicePromiseClient,
  action: ReturnType<typeof Actions.listFiltersReq>,
) {
  try {
    const { limit, skip, includeUserDisabled } = action.payload
    const req = new filteringv1.ListFiltersRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    req.setIncludeUserDisabled(includeUserDisabled)

    const resp: filteringv1.ListFiltersResponse = yield call(
      [client, client.listFilters],
      req,
      authMetadata(),
    )
    yield put(Actions.listFiltersResp(resp.getTotalCount(), resp.getFiltersList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listFiltersErr(err))
  }
}

export function* get(
  client: FilterServicePromiseClient,
  action: ReturnType<typeof Actions.getFilterReq>,
) {
  try {
    const req = new filteringv1.GetFilterRequest()
    const { id } = action.payload
    req.setFilterId(id)
    const resp: filteringv1.GetFilterResponse = yield call(
      [client, client.getFilter],
      req,
      authMetadata(),
    )
    const filter = resp.getFilter()
    if (!filter) {
      throw new Error('missing filter')
    }
    yield put(Actions.getFilterResp(filter))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.getFilterErr(err))
  }
}

export function* create(
  client: FilterServicePromiseClient,
  action: ReturnType<typeof Actions.createFilterReq>,
) {
  try {
    const { filter, user } = action.payload
    const req = new filteringv1.CreateFilterRequest()
    req.setFilter(filter)
    const resp: filteringv1.CreateFilterResponse = yield call(
      [client, client.createFilter],
      req,
      authMetadata(),
    )
    const newFilter = resp.getFilter()
    if (!newFilter) {
      throw new Error('missing filter')
    }

    // Update user filters
    if (user) {
      const newUserFilter = new userv1.UserFilter()
      newUserFilter.setFilterId(newFilter.getFilterId())
      newUserFilter.setDisabled(false)
      newUserFilter.setDisabledEmailTypesList(bookingFilterRelatedEmailTypes)

      const filterList = [newUserFilter].concat(user.getFiltersList())
      user.setFiltersList(filterList)
      yield put(UserActions.editUserReq(user))
    }

    yield put(
      NotificationActions.send({
        key: `filter-${newFilter.getFilterId()}`,
        kind: 'success',
        message: 'Filter Created',
        description: 'The filter was created successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.createFilterResp(newFilter))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createFilterErr(err))
  }
}

export function* edit(
  client: FilterServicePromiseClient,
  action: ReturnType<typeof Actions.editFilterReq>,
) {
  try {
    const { filter } = action.payload
    const req = new filteringv1.EditFilterRequest()
    req.setFilter(filter)
    const resp: filteringv1.EditFilterResponse = yield call(
      [client, client.editFilter],
      req,
      authMetadata(),
    )
    const editedFilter = resp.getFilter()
    if (!editedFilter) {
      throw new Error('missing filter')
    }

    yield put(
      NotificationActions.send({
        key: `filter-${editedFilter.getFilterId()}`,
        kind: 'success',
        message: 'Filter Updated',
        description: 'The filter was updated successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.editFilterResp(editedFilter))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editFilterErr(err))
  }
}

export function* del(
  client: FilterServicePromiseClient,
  action: ReturnType<typeof Actions.deleteFilterReq>,
) {
  try {
    const { id, user } = action.payload
    const req = new filteringv1.DeleteFilterRequest()
    req.setFilterId(id)
    yield call([client, client.deleteFilter], req, authMetadata())

    // Update user filters
    if (user) {
      user.setFiltersList(user.getFiltersList().filter((uf) => uf.getFilterId() !== id))
      yield put(UserActions.editUserReq(user))
    }

    yield put(
      NotificationActions.send({
        key: `filter-${id}`,
        kind: 'success',
        message: 'Filter Deleted',
        description: 'The filter was deleted successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteFilterResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteFilterErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.filter)
  yield takeLatest(GET_REQ, get, clients.filter)
  yield takeLatest(CREATE_REQ, create, clients.filter)
  yield takeLatest(EDIT_REQ, edit, clients.filter)
  yield takeLatest(DELETE_REQ, del, clients.filter)
}
