import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import * as currencyv1 from 'proto/currency/v1/currency_pb'

import { Pagination, defaultPageSize } from './query'

export interface CurrencyFilter {
  incoming_currency?: Array<string>
  sourceType?: Array<string>
  lookupDate?: Timestamp
}

export type CurrencyPage = {
  pagination: Pagination
  filter: CurrencyFilter
  sorting: currencyv1.CurrencySorting
}

export const getDefaultCurrencyPage = (): CurrencyPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new currencyv1.CurrencySorting(),
})
