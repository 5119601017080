import * as deviationv1 from 'proto/booking/v1/deviation_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_REQ = 'swift2/deviation/LIST_REQ'
export const LIST_RESP = 'swift2/deviation/LIST_RESP'
export const LIST_ERR = 'swift2/deviation/LIST_ERR'
export const CREATE_REQ = 'swift2/deviation/CREATE_REQ'
export const CREATE_RESP = 'swift2/deviation/CREATE_RESP'
export const CREATE_ERR = 'swift2/deviation/CREATE_ERR'
export const EDIT_REQ = 'swift2/deviation/EDIT_REQ'
export const EDIT_RESP = 'swift2/deviation/EDIT_RESP'
export const EDIT_ERR = 'swift2/deviation/EDIT_ERR'
export const DELETE_REQ = 'swift2/deviation/DELETE_REQ'
export const DELETE_RESP = 'swift2/deviation/DELETE_RESP'
export const DELETE_ERR = 'swift2/deviation/DELETE_ERR'

export const Actions = {
  listReq: (bookingRef: string) => createAction(LIST_REQ, { bookingRef }),
  listResp: (deviations: Array<deviationv1.Deviation>, bookingRef: string) =>
    createAction(LIST_RESP, { deviations, bookingRef }),
  listErr: (err: Error) => createAction(LIST_ERR, { err }),
  createReq: (deviation: deviationv1.Deviation) => createAction(CREATE_REQ, { deviation }),
  createResp: (deviation: deviationv1.Deviation) => createAction(CREATE_RESP, { deviation }),
  createErr: (err: Error) => createAction(CREATE_ERR, { err }),
  editReq: (deviation: deviationv1.Deviation) => createAction(EDIT_REQ, { deviation }),
  editResp: (deviation: deviationv1.Deviation) => createAction(EDIT_RESP, { deviation }),
  editErr: (err: Error) => createAction(EDIT_ERR, { err }),
  deleteReq: (deviationId: number) => createAction(DELETE_REQ, { deviationId }),
  deleteResp: (deviationId: number) => createAction(DELETE_RESP, { deviationId }),
  deleteErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
