import * as grpcWeb from 'grpc-web'
import { call, getContext, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ContextItems, CustomContext } from 'sagas/context'

import * as commonQuery from 'proto/common/query_pb'
import { OrderServicePromiseClient } from 'proto/order/v1/order_grpc_web_pb'
import * as orderv1 from 'proto/order/v1/order_pb'

import { Actions as BookingActions } from '../../store/booking/booking/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'
import {
  APPLY_ORDER_EVENT_REQ,
  Actions,
  CREATE_REQ,
  GET_EVENTS_REQ,
  GET_REQ,
  GET_STATS_REQ,
  LIST_REQ,
  UPDATE_REQ,
} from '../../store/order/order/actions'
import { Actions as ProductActions } from '../../store/order/product/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.listOrdersReq>,
) {
  try {
    const { page } = action.payload
    const req = new orderv1.ListOrdersRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new orderv1.OrderFilter()
      if (page.filter.type && page.filter.type.length > 0) {
        filter.setTypeList(page.filter.type)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sorting = new orderv1.OrderSorting()
      sorting.setField(page.sorting.getField())
      sorting.setOrdering(page.sorting.getOrdering())
      req.setSorting(sorting)
    }

    const resp: orderv1.ListOrdersResponse = yield call(
      [client, client.listOrders],
      req,
      authMetadata(),
    )
    yield put(Actions.listOrdersResp(resp.getTotalCount(), resp.getOrdersList(), page))
  } catch (err: any) {
    yield put(Actions.listOrdersErr(err))
  }
}

export function* get(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.getOrderReq>,
) {
  try {
    const { ref, fetchLinkedItems, fetchEvents } = action.payload
    const req = new orderv1.GetOrderRequest()
    req.setOrderRef(ref)
    const resp: orderv1.GetOrderResponse = yield call(
      [client, client.getOrder],
      req,
      authMetadata(),
    )
    const order = resp.getOrder()
    if (!order) {
      throw new Error('missing order')
    }

    // Fetch the order type and item types.
    yield put(ProductActions.listProductsReq({ pagination: { skip: 0, limit: 0 } }))

    if (fetchLinkedItems) {
      // Fetch the linked booking.
      if (order.getBookingRef() !== '') {
        yield put(BookingActions.getBookingReq(order.getBookingRef(), false, false))
      }
    }

    if (fetchEvents) {
      yield put(Actions.getOrderEventsReq(order.getOrderRef()))
    }

    yield put(Actions.getOrderResp(order))
  } catch (err: any) {
    if (err.code && err.code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getOrderResp(undefined))
    } else {
      yield put(Actions.getOrderErr(err))
    }
  }
}

export function* getStats(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.getOrderStatsReq>,
) {
  try {
    const { query } = action.payload
    const req = new orderv1.GetOrderStatsRequest()
    req.setQuery(query)
    const resp: orderv1.GetOrderStatsResponse = yield call(
      [client, client.getOrderStats],
      req,
      authMetadata(),
    )
    const stats = resp.getStats()
    if (!stats) {
      throw new Error('missing stats')
    }

    yield put(Actions.getOrderStatsResp(stats))
  } catch (err: any) {
    yield put(Actions.getOrderStatsErr(err))
  }
}

export function* create(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.createOrderReq>,
) {
  try {
    const { order } = action.payload
    const req = new orderv1.CreateOrderRequest()
    req.setOrder(order)
    const resp: orderv1.CreateOrderResponse = yield call(
      [client, client.createOrder],
      req,
      authMetadata(),
    )
    const newOrder = resp.getOrder()
    if (!newOrder) {
      throw new Error('missing order')
    }
    yield put(Actions.createOrderResp(newOrder))

    yield put(
      NotificationActions.send({
        key: `order-${newOrder.getOrderRef()}`,
        kind: 'success',
        message: 'Order created',
        description: 'Your order has been created, a transport will be booked shortly.',
        dismissAfter: 4500,
      }),
    )
    const { context }: CustomContext = yield getContext(ContextItems.customContext)
    const path =
      newOrder.getOrderType() === orderv1.OrderType.RETURN
        ? `/bookings/create?orderRefs=${newOrder.getOrderRef()}`
        : `/orders/${newOrder.getOrderRef()}`

    context.navigate(path)
  } catch (err: any) {
    yield put(Actions.createOrderErr(err))
  }
}

export function* update(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.updateOrderReq>,
) {
  try {
    const { order } = action.payload
    const req = new orderv1.UpdateOrderRequest()
    req.setOrder(order)
    const resp: orderv1.UpdateOrderResponse = yield call(
      [client, client.updateOrder],
      req,
      authMetadata(),
    )
    const updatedOrder = resp.getOrder()
    if (!updatedOrder) {
      throw new Error('missing order')
    }
    yield put(Actions.updateOrderResp(updatedOrder))
  } catch (err: any) {
    yield put(Actions.updateOrderErr(err))
  }
}

export function* getEvents(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.getOrderEventsReq>,
) {
  try {
    const { ref } = action.payload
    const req = new orderv1.GetOrderEventsRequest()
    req.setOrderRef(ref)
    const resp: orderv1.GetOrderEventsResponse = yield call(
      [client, client.getOrderEvents],
      req,
      authMetadata(),
    )
    const events = resp.getEventsList()
    yield put(Actions.getOrderEventsResp(events, ref))
  } catch (err: any) {
    yield put(Actions.getOrderEventsErr(err))
  }
}

export function* applyEvent(
  client: OrderServicePromiseClient,
  action: ReturnType<typeof Actions.applyOrderEventReq>,
) {
  try {
    const { event } = action.payload
    const req = new orderv1.ApplyOrderEventRequest()
    req.setEvent(event)
    const resp: orderv1.ApplyOrderEventResponse = yield call(
      [client, client.applyOrderEvent],
      req,
      authMetadata(),
    )
    const updatedOrder = resp.getOrder()
    if (!updatedOrder) {
      throw new Error('missing order')
    }
    yield put(Actions.applyOrderEventResp(updatedOrder))
  } catch (err: any) {
    yield put(Actions.applyOrderEventErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.order)
  yield takeLatest(GET_REQ, get, clients.order)
  yield takeLatest(GET_STATS_REQ, getStats, clients.order)
  yield takeEvery(GET_EVENTS_REQ, getEvents, clients.order)
  yield takeEvery(CREATE_REQ, create, clients.order)
  yield takeEvery(UPDATE_REQ, update, clients.order)
  yield takeEvery(APPLY_ORDER_EVENT_REQ, applyEvent, clients.order)
}
