import * as supplierv1 from 'proto/booking/v1/supplier_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_REQ = 'swift2/supplier/LIST_REQ'
export const LIST_RESP = 'swift2/supplier/LIST_RESP'
export const LIST_ERR = 'swift2/supplier/LIST_ERR'

export const CREATE_REQ = 'swift2/supplier/CREATE_REQ'
export const CREATE_RESP = 'swift2/supplier/CREATE_RESP'
export const CREATE_ERR = 'swift2/supplier/CREATE_ERR'

export const EDIT_REQ = 'swift2/supplier/EDIT_REQ'
export const EDIT_RESP = 'swift2/supplier/EDIT_RESP'
export const EDIT_ERR = 'swift2/supplier/EDIT_ERR'

export const DELETE_REQ = 'swift2/supplier/DELETE_REQ'
export const DELETE_RESP = 'swift2/supplier/DELETE_RESP'
export const DELETE_ERR = 'swift2/supplier/DELETE_ERR'

export const CREATE_SERVICE_REQ = 'swift2/supplier/CREATE_SERVICE_REQ'
export const CREATE_SERVICE_RESP = 'swift2/supplier/CREATE_SERVICE_RESP'
export const CREATE_SERVICE_ERR = 'swift2/supplier/CREATE_SERVICE_ERR'

export const EDIT_SERVICE_REQ = 'swift2/supplier/EDIT_SERVICE_REQ'
export const EDIT_SERVICE_RESP = 'swift2/supplier/EDIT_SERVICE_RESP'
export const EDIT_SERVICE_ERR = 'swift2/supplier/EDIT_SERVICE_ERR'

export const DELETE_SERVICE_REQ = 'swift2/supplier/DELETE_SERVICE_REQ'
export const DELETE_SERVICE_RESP = 'swift2/supplier/DELETE_SERVICE_RESP'
export const DELETE_SERVICE_ERR = 'swift2/supplier/DELETE_SERVICE_ERR'

export const LIST_CONFIGS_REQ = 'swift2/supplier/LIST_CONFIGS_REQ'
export const LIST_CONFIGS_RESP = 'swift2/supplier/LIST_CONFIGS_RESP'
export const LIST_CONFIGS_ERR = 'swift2/supplier/LIST_CONFIGS_ERR'

export const SET_CONFIG_REQ = 'swift2/supplier/SET_CONFIG_REQ'
export const SET_CONFIG_RESP = 'swift2/supplier/SET_CONFIG_RESP'
export const SET_CONFIG_ERR = 'swift2/supplier/SET_CONFIG_ERR'

export const CREATE_SUPPLIER_WITH_SERVICES_REQ = 'swift2/supplier/CREATE_SUPPLIER_WITH_SERVICES_REQ'
export const CREATE_SUPPLIER_WITH_SERVICES_RESP =
  'swift2/supplier/CREATE_SUPPLIER_WITH_SERVICES_RESP'
export const CREATE_SUPPLIER_WITH_SERVICES_ERR = 'swift2/supplier/CREATE_SUPPLIER_WITH_SERVICES_ERR'

export const EDIT_SUPPLIER_WITH_SERVICES_REQ = 'swift2/supplier/EDIT_SUPPLIER_WITH_SERVICES_REQ'
export const EDIT_SUPPLIER_WITH_SERVICES_RESP = 'swift2/supplier/EDIT_SUPPLIER_WITH_SERVICES_RESP'
export const EDIT_SUPPLIER_WITH_SERVICES_ERR = 'swift2/supplier/EDIT_SUPPLIER_WITH_SERVICES_ERR'

export const Actions = {
  listReq: () => createAction(LIST_REQ, {}),
  listResp: (count: number, suppliers: supplierv1.Supplier[]) =>
    createAction(LIST_RESP, { count, suppliers }),
  listErr: (err: Error) => createAction(LIST_ERR, { err }),
  createReq: (supplier: supplierv1.Supplier, disablePricing: boolean) =>
    createAction(CREATE_REQ, { supplier, disablePricing }),
  createResp: (supplier: supplierv1.Supplier) => createAction(CREATE_RESP, { supplier }),
  createErr: (err: Error) => createAction(CREATE_ERR, { err }),
  editReq: (supplier: supplierv1.Supplier) => createAction(EDIT_REQ, { supplier }),
  editResp: (supplier: supplierv1.Supplier) => createAction(EDIT_RESP, { supplier }),
  editErr: (err: Error) => createAction(EDIT_ERR, { err }),
  deleteReq: (supplierID: number) => createAction(DELETE_REQ, { supplierID }),
  deleteResp: (supplierID: number) => createAction(DELETE_RESP, { supplierID }),
  deleteErr: (err: Error) => createAction(DELETE_ERR, { err }),
  createServiceReq: (service: supplierv1.SupplierService) =>
    createAction(CREATE_SERVICE_REQ, { service }),
  createServiceResp: (service: supplierv1.SupplierService) =>
    createAction(CREATE_SERVICE_RESP, { service }),
  createServiceErr: (err: Error) => createAction(CREATE_SERVICE_ERR, { err }),
  editServiceReq: (service: supplierv1.SupplierService) =>
    createAction(EDIT_SERVICE_REQ, { service }),
  editServiceResp: (service: supplierv1.SupplierService) =>
    createAction(EDIT_SERVICE_RESP, { service }),
  editServiceErr: (err: Error) => createAction(EDIT_SERVICE_ERR, { err }),
  deleteServiceReq: (serviceID: number) => createAction(DELETE_SERVICE_REQ, { serviceID }),
  deleteServiceResp: (serviceID: number) => createAction(DELETE_SERVICE_RESP, { serviceID }),
  deleteServiceErr: (err: Error) => createAction(DELETE_SERVICE_ERR, { err }),
  listConfigsReq: () => createAction(LIST_CONFIGS_REQ, {}),
  listConfigsResp: (count: number, configs: supplierv1.SupplierConfig[]) =>
    createAction(LIST_CONFIGS_RESP, { count, configs }),
  listConfigsErr: (err: Error) => createAction(LIST_CONFIGS_ERR, { err }),
  setConfigReq: (supplierConfig: supplierv1.SupplierConfig) =>
    createAction(SET_CONFIG_REQ, { supplierConfig }),
  setConfigResp: (supplierConfig: supplierv1.SupplierConfig) =>
    createAction(SET_CONFIG_RESP, { supplierConfig }),
  setConfigErr: (err: Error) => createAction(SET_CONFIG_ERR, { err }),
  createSupplierWithServicesReq: (supplier: supplierv1.Supplier) =>
    createAction(CREATE_SUPPLIER_WITH_SERVICES_REQ, { supplier }),
  createSupplierWithServicesResp: (supplier: supplierv1.Supplier) =>
    createAction(CREATE_SUPPLIER_WITH_SERVICES_RESP, { supplier }),
  createSupplierWithServicesErr: (err: Error) =>
    createAction(CREATE_SUPPLIER_WITH_SERVICES_ERR, { err }),
  editSupplierWithServicesReq: (
    supplier: supplierv1.Supplier,
    supplierConfig?: supplierv1.SupplierConfig,
    deletedServices?: number[],
    modifiedServices?: number[],
  ) =>
    createAction(EDIT_SUPPLIER_WITH_SERVICES_REQ, {
      supplier,
      supplierConfig,
      deletedServices,
      modifiedServices,
    }),
  editSupplierWithServicesResp: (supplier: supplierv1.Supplier) =>
    createAction(EDIT_SUPPLIER_WITH_SERVICES_RESP, { supplier }),
  editSupplierWithServicesErr: (err: Error) =>
    createAction(EDIT_SUPPLIER_WITH_SERVICES_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
