import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { UserOutlined } from '@ant-design/icons'
import { Avatar, Card, Dropdown, Layout } from 'antd'
import { RouteNames } from 'route/routes-map'

import * as organizationv1 from 'proto/iam/v1/organization_pb'
import * as userv1 from 'proto/iam/v1/user_pb'

import * as userSelectors from '../store/iam/user/reducer'
import { RootState } from '../store/reducer'

import { LinkComponent } from 'components/Links/Link'

import './Header.css'

export interface Props {
  user?: userv1.User
  organization?: organizationv1.Organization
  onLogout: () => void
}

const avatarSize = 42

export const Header: React.FC<Props> = ({ user, organization, onLogout }) => {
  const { Meta } = Card

  const rawImage = useSelector((state: RootState) =>
    userSelectors.getProfilePictureCurrentUser(state),
  )
  const [profilePicture, setProfilePicture] = useState(getUserProfilePicture(rawImage))

  useEffect(() => {
    setProfilePicture(getUserProfilePicture(rawImage))
  }, [rawImage])

  const environmentSpecificBanner = useMemo((): string => {
    if (process.env.NODE_ENV === 'development') {
      return 'Development'
    }
    const currentUrl: string = window.location.hostname
    try {
      const firstPartOfHostName = currentUrl?.split('.')[0] ?? ''
      return firstPartOfHostName?.includes('staging') ? 'Staging' : ''
    } catch {
      return ''
    }
  }, [])

  return (
    <Layout.Header className="header__layout header--background-white">
      <div className="header__container">
        {organization && (
          <img
            src={'/logo-' + organization.getOrganization() + '.png'}
            className="header__logo-organization"
            alt="logo"
          />
        )}
        <span className="header__env-banner">{environmentSpecificBanner}</span>

        <Dropdown
          placement="bottomLeft"
          dropdownRender={() => (
            <Card
              className="header__profile-card"
              actions={[
                <LinkComponent to={RouteNames.Profile}>Profile</LinkComponent>,
                <LinkComponent to={RouteNames.Privacy}>Privacy</LinkComponent>,
                <a
                  role="button"
                  onClick={(e) => {
                    e.preventDefault()
                    onLogout()
                  }}
                >
                  Sign out
                </a>,
              ]}
            >
              {profilePicture.length ? (
                <Meta
                  avatar={<Avatar size={avatarSize} src={profilePicture} />}
                  title={user?.getName() + ' ' + user?.getSurname()}
                  description={user?.getEmail()}
                />
              ) : (
                <Meta
                  avatar={
                    <Avatar
                      style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                      size={avatarSize}
                    >
                      {getUserInitials(user)}
                    </Avatar>
                  }
                  title={user?.getName() + ' ' + user?.getSurname()}
                  description={user?.getEmail()}
                />
              )}
            </Card>
          )}
        >
          {profilePicture.length ? (
            <Avatar size={avatarSize} src={profilePicture} />
          ) : (
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size={avatarSize}>
              {getUserInitials(user)}
            </Avatar>
          )}
        </Dropdown>
      </div>
    </Layout.Header>
  )
}

function getUserProfilePicture(img?: string | Uint8Array): string {
  if (img) {
    return URL.createObjectURL(new Blob([img], { type: 'image/png' }))
  }

  return ''
}

function getUserInitials(user?: userv1.User) {
  if (user && user.getName().length > 0 && user.getSurname().length > 0) {
    return user.getName().charAt(0) + user.getSurname().charAt(0)
  }
  return <UserOutlined />
}
