import * as usergroupv1 from 'proto/iam/v1/usergroup_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_REQ = 'swift2/usergroup/LIST_REQ'
export const LIST_RESP = 'swift2/usergroup/LIST_RESP'
export const LIST_ERR = 'swift2/usergroup/LIST_ERR'
export const GET_REQ = 'swift2/usergroup/GET_REQ'
export const GET_RESP = 'swift2/usergroup/GET_RESP'
export const GET_ERR = 'swift2/usergroup/GET_ERR'
export const CREATE_REQ = 'swift2/usergroup/CREATE_REQ'
export const CREATE_RESP = 'swift2/usergroup/CREATE_RESP'
export const CREATE_ERR = 'swift2/usergroup/CREATE_ERR'

export const Actions = {
  listUserGroupsReq: (limit: number, skip: number) => createAction(LIST_REQ, { limit, skip }),
  listUserGroupsResp: (count: number, userGroups: usergroupv1.UserGroup[]) =>
    createAction(LIST_RESP, { count, userGroups }),
  listUserGroupsErr: (err: Error) => createAction(LIST_ERR, { err }),
  getUserGroupReq: (id: number) => createAction(GET_REQ, { id }),
  getUserGroupResp: (userGroup?: usergroupv1.UserGroup) => createAction(GET_RESP, { userGroup }),
  getUserGroupErr: (err: Error) => createAction(GET_ERR, { err }),
  createUserGroupReq: (userGroup: usergroupv1.UserGroup) => createAction(CREATE_REQ, { userGroup }),
  createUserGroupResp: (userGroup: usergroupv1.UserGroup) =>
    createAction(CREATE_RESP, { userGroup }),
  createUserGroupErr: (err: Error) => createAction(CREATE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
