import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as commonBooking from 'proto/common/booking_pb'

import { Pagination, defaultPageSize } from './query'

export interface BookingFilter {
  userID?: Array<string>
  transportOperator?: Array<string>
  organizationID?: Array<number>
  userGroupID?: Array<number>
  branchID?: Array<number>
  status?: Array<bookingv1.Booking.Status>
  search?: string
  sender?: Array<number>
  senderCountry?: Array<string>
  receiver?: Array<number>
  receiverCountry?: Array<string>
  transportMode?: Array<commonBooking.TransportMode>
  bookingRef?: Array<string>
  serviceSelection?: Array<bookingv1.Booking.ServiceSelection>
  shippable?: boolean
  source?: Array<number>
}

export type BookingPage = {
  pagination: Pagination
  filter: BookingFilter
  sorting: bookingv1.BookingSorting
  filterID: number
}

export const getDefaultBookingPage = (): BookingPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new bookingv1.BookingSorting(),
  filterID: 0,
})

// A very permissive type so we can convert some indexes to a JSON string in
// booking to allow conversion to a query parameter string.
export type ParsingBookingPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
  filterID?: number
}
