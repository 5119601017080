import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DiffFilled, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import Icon, { FileTextFilled } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { RouteNames, requiredRolesRouteMap } from 'route/routes-map'

import { Organization } from 'proto/iam/v1/organization_pb'
import { User } from 'proto/iam/v1/user_pb'

import * as Icons from '../components/icons'
import { DividerComponent, IconButton } from 'components'
import { LinkComponent } from 'components/Links/Link'

import { containRole } from 'helpers/user'

import './Sidebar.css'
import { MenuItem, keyFromPath } from './SiderMenuItems'

type SideBarChildItem = MenuItem & {
  featureFlag?: string
  requiredRoles?: User.Role[]
}

interface SideBarItem {
  label: React.JSX.Element
  key: string
  icon: React.JSX.Element
  path?: string
  children?: SideBarChildItem[]
  requiredRoles?: User.Role[]
  featureFlag?: string
  onClick?: () => void
}

export interface Props {
  organization?: Organization
  collapsed: boolean
  roles: User.Role[]
  onCollapse: () => void
  onLogout: () => void
}

export const Sidebar = ({ collapsed, roles, onLogout, onCollapse }: Props) => {
  const flags = useFlags()
  const [image, setImage] = useState<any>()
  const [firstCollapse, setFirstCollapse] = useState(true)

  useEffect(() => {
    import('../assets/images/logo-intereast-red.png').then((img) => {
      setImage(img.default)
    })
  }, [])

  const onCollapseTriggered = () => {
    if (firstCollapse) {
      setFirstCollapse(false)
    } else {
      onCollapse()
    }
  }

  const menuItems: SideBarItem[] = [
    {
      label: <LinkComponent underline={false} to={RouteNames.Home} label="Home" />,
      key: 'home',
      icon: <Icon component={Icons.Building} className="sider__nav-item" />,
      requiredRoles: requiredRolesRouteMap[RouteNames.Home].requiredRoles,
    },
    {
      label: <>Order</>,
      key: 'order',
      icon: <Icon component={Icons.StickyNote} className="sider__nav-item" />,
      featureFlag: 'orderFlow',
      children: [
        {
          label: <LinkComponent underline={false} to={RouteNames.Orders} label="Orders" />,
          key: 'orders',
          icon: <FileTextFilled className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.Orders].requiredRoles,
        },
        {
          label: <LinkComponent underline={false} to={RouteNames.Products} label="Products" />,
          key: 'products',
          icon: <Icon component={Icons.Tag} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.Products].requiredRoles,
        },
      ],
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Bookings} label="Bookings" />,
      key: 'bookings',
      icon: <Icon component={Icons.ClipboardList} className="sider__nav-item" />,
      requiredRoles: requiredRolesRouteMap[RouteNames.Bookings].requiredRoles,
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Drafts} label="Drafts" />,
      icon: <Icon component={Icons.Pencil} className="sider__nav-item" />,
      key: 'drafts',
      featureFlag: 'draftsFe',
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Quotes} label="Quotes" />,
      icon: <Icon component={Icons.Coins} className="sider__nav-item" />,
      key: 'quotes',
      featureFlag: 'quotesFe',
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Contracts} label="Contracts" />,
      icon: <DiffFilled className="sider__nav-item" />,
      key: 'contracts',
      featureFlag: 'contractsFe',
      requiredRoles: requiredRolesRouteMap[RouteNames.Contracts].requiredRoles,
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Shipments} label="Shipments" />,
      icon: <Icon component={Icons.Boxes} className="sider__nav-item" />,
      key: 'shipments',
      requiredRoles: requiredRolesRouteMap[RouteNames.Shipments].requiredRoles,
    },
    {
      label: <>Finance</>,
      key: 'finance',
      icon: <Icon component={Icons.Coins} className="sider__nav-item" />,
      children: [
        {
          label: <LinkComponent underline={false} to={RouteNames.Invoices} label="Invoices" />,
          key: 'invoices',
          icon: <Icon component={Icons.InvoiceDollar} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.Invoices].requiredRoles,
        },
        {
          label: <LinkComponent underline={false} to={RouteNames.Costs} label="Costs" />,
          key: 'costs',
          icon: <Icon component={Icons.Coins} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.Costs].requiredRoles,
        },
        {
          label: (
            <LinkComponent underline={false} to={RouteNames.Profatibility} label="Profitability" />
          ),
          key: 'profitability',
          icon: <Icon component={Icons.InvoiceDollar} className="sider__nav-item" />,
          featureFlag: 'profitabilityFe',
          requiredRoles: requiredRolesRouteMap[RouteNames.Profatibility].requiredRoles,
        },
        {
          label: (
            <LinkComponent underline={false} to={RouteNames.ArticeTypes} label="Article Types" />
          ),
          key: 'articleTypes',
          icon: <Icon component={Icons.Coins} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.ArticeTypes].requiredRoles,
        },
      ],
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Addressess} label="Addresses" />,
      key: 'addresses',
      icon: <Icon component={Icons.AddressBook} className="sider__nav-item" />,
      requiredRoles: requiredRolesRouteMap[RouteNames.Addressess].requiredRoles,
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Reports} label="Reports" />,
      key: 'reports',
      icon: <Icon component={Icons.Poll} className="sider__nav-item" />,
      featureFlag: 'reportsFe',
      requiredRoles: requiredRolesRouteMap[RouteNames.Reports].requiredRoles,
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Surcharges} label="Surcharges" />,
      key: 'surcharges',
      icon: <Icon component={Icons.ClipboardList} className="sider__nav-item" />,
      featureFlag: 'surchargeFe',
      requiredRoles: requiredRolesRouteMap[RouteNames.Surcharges].requiredRoles,
    },
    {
      label: <></>,
      key: 'admin',
      featureFlag: 'oldAdminView',
      icon: <Icon component={Icons.UserCog} className="sider__nav-item" />,
    },
    {
      key: 'adminNew',
      label: <>Admin</>,
      featureFlag: 'adminRefactor',
      icon: <Icon component={Icons.UserCog} className="sider__nav-item" />,
      requiredRoles: requiredRolesRouteMap[RouteNames.AdminNew].requiredRoles,
      children: [
        {
          label: <LinkComponent underline={false} to={RouteNames.SystemAdmin} label="System" />,
          key: 'systemAdin',
          icon: <Icon component={Icons.UserCog} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.SystemAdmin].requiredRoles,
        },
        {
          label: (
            <LinkComponent
              underline={false}
              to={RouteNames.OrganizationAdmin}
              label="Organization"
            />
          ),
          key: 'organizationAdmin',
          icon: <Icon component={Icons.UserCog} className="sider__nav-item" />,
          requiredRoles: requiredRolesRouteMap[RouteNames.OrganizationAdmin].requiredRoles,
        },
      ],
    },
    {
      label: <DividerComponent />,
      key: 'divider',
      icon: <></>,
    },
    {
      label: <LinkComponent underline={false} to={RouteNames.Logout} label="Sign out" />,
      key: 'signOut',
      icon: <Icon component={Icons.SignOut} className="sider__nav-item" />,
      onClick: () => {
        onLogout()
      },
    },
  ]

  const allowedByRolesAndRoutes = (
    item: SideBarItem | SideBarChildItem,
    userRoles: User.Role[],
  ) => {
    const allowedByFlag = item.featureFlag ? flags[item.featureFlag] : true
    // no rules means everyone can access
    const allowedByRole = !item.requiredRoles
      ? true
      : item.requiredRoles?.some((role) => userRoles.includes(role))
    return allowedByFlag && allowedByRole
  }

  const navigationItems: SideBarItem[] = useMemo(() => {
    return menuItems
      .map((item) => {
        if (item.key === 'divider') {
          return {
            type: 'divider',
            ...item,
          }
        }
        // Replace the admin placeholder menu item with the correct admin page depending on the user's roles
        if (item.key === 'admin') {
          const requiermentsForMonitor = requiredRolesRouteMap[RouteNames.AdminMonitor]
          const adminNav = requiermentsForMonitor.requiredRoles?.some((role) => {
            return containRole([role], roles)
          })
          const targetAdminPage = adminNav ? RouteNames.AdminMonitor : RouteNames.AdminUsers
          const requiredRoles =
            requiredRolesRouteMap[adminNav ? RouteNames.AdminMonitor : RouteNames.AdminUsers]
              .requiredRoles
          if (targetAdminPage) {
            return {
              label: <LinkComponent underline={false} to={targetAdminPage} label="Admin" />,
              key: 'admin',
              icon: <Icon component={Icons.UserCog} className="sider__nav-item" />,
              requiredRoles: requiredRoles,
              featureFlag: item.featureFlag,
            }
          }
          return item
        }
        return item
      })
      .filter((item) => {
        // Remove nested routes that are disabled by feature flags
        if (item.children) {
          item.children = item.children?.filter((child) => {
            return allowedByRolesAndRoutes(child, roles)
          })
          // if all nested routes were removed, remove the parent route as well
          if (item.children.length === 0) {
            return false
          }
        }
        return allowedByRolesAndRoutes(item, roles)
      })
  }, [roles, flags])

  return (
    <div className="sider">
      <Layout.Sider
        className="sider__container"
        breakpoint="sm"
        collapsible
        collapsed={collapsed}
        trigger={null}
        onBreakpoint={onCollapseTriggered}
        width={160}
      >
        <div className="sider__logo">
          <LinkComponent to="/" underline={false}>
            <img src={image} style={{ height: 28 }} alt="logo" loading="lazy" />
          </LinkComponent>
        </div>
        <Menu
          triggerSubMenuAction={'hover'}
          defaultSelectedKeys={['/']}
          selectedKeys={[keyFromPath(useLocation().pathname)]}
          mode="inline"
          inlineIndent={8}
          items={navigationItems}
        />
      </Layout.Sider>
      <div className="sider__expand-button">
        <div className="sider__button">
          <IconButton
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={onCollapse}
            size="small"
          />
          <div className="sider__expand-text">{collapsed ? 'Expand' : 'Close'}</div>
        </div>
      </div>
    </div>
  )
}
