import * as deviationv1 from 'proto/booking/v1/deviation_pb'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: Array<deviationv1.Deviation> }
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState, bookingRef: string) =>
  state.booking.deviation.items[bookingRef] || []
export const getErr = (state: RootState) => state.booking.nomination.err
export const getIsFetching = (state: RootState) => state.booking.nomination.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { deviations, bookingRef } = action.payload
      const items = s.items
      items[bookingRef] = deviations
      return { ...s, items, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { deviation } = action.payload

      const items = {
        ...s.items,
        [deviation.getBookingRef()]: [...s.items[deviation.getBookingRef()], deviation],
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { deviation } = action.payload

      const filteredBookingDeviations = s.items[deviation.getBookingRef()].filter(
        (d) => d.getDeviationId() !== deviation.getDeviationId(),
      )

      const items = {
        ...s.items,
        [deviation.getBookingRef()]: [...filteredBookingDeviations, deviation],
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { deviationId } = action.payload

      const items = Object.keys(s.items).reduce<{ [key: string]: Array<deviationv1.Deviation> }>(
        (items, key) => {
          if (s.items[key].some((i) => i.getDeviationId() === deviationId)) {
            const filteredDeviations = s.items[key].filter(
              (i) => i.getDeviationId() !== deviationId,
            )
            items[key] = filteredDeviations
          } else {
            items[key] = s.items[key]
          }
          return items
        },
        {},
      )

      return { ...s, items, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetching: false }
    }

    default:
      return s
  }
}
