import * as bookingtemplatev1 from 'proto/booking/v1/bookingtemplate_pb'

import * as templateTypes from '../../../types/templates'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly pages: Map<templateTypes.TemplatePage, Array<number>>
  readonly items: { [key: string]: bookingtemplatev1.BookingTemplate }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly latestCreatedTemplateId: number | undefined
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  latestCreatedTemplateId: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.booking.bookingTemplate.items).map(
    (id) => state.booking.bookingTemplate.items[id],
  )
}
export const getItemsById = (state: RootState) => {
  return state.booking.bookingTemplate.items
}
export const getCount = (state: RootState) => state.booking.bookingTemplate.count
export const getPageItems = (state: RootState, page: templateTypes.TemplatePage) => {
  const items = state.booking.bookingTemplate.items
  const pageItems = state.booking.bookingTemplate.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<bookingtemplatev1.BookingTemplate>)
}
export const getErr = (state: RootState) => state.booking.bookingTemplate.err
export const getIsFetching = (state: RootState) => state.booking.bookingTemplate.isFetching
export const getLatestCreatedTemplateId = (state: RootState) =>
  state.booking.bookingTemplate.latestCreatedTemplateId

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, bookingTemplates, page } = action.payload
      const newItems = bookingTemplates.reduce<{
        [key: string]: bookingtemplatev1.BookingTemplate
      }>((map, bt) => {
        map[bt.getBookingTemplateId()] = bt
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      const pageItems = bookingTemplates.map((s) => s.getBookingTemplateId())
      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)

      return { ...s, items, count, isFetching: false, latestCreatedTemplateId: undefined }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { bookingTemplate } = action.payload
      if (!bookingTemplate) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [bookingTemplate.getBookingTemplateId()]: bookingTemplate,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { bookingTemplate, page } = action.payload
      const latestCreatedTemplateId = bookingTemplate.getBookingTemplateId()
      const items = {
        ...s.items,
        [latestCreatedTemplateId]: bookingTemplate,
      }

      // Update page items
      if (page) {
        const pageItems = s.pages.get(page)
        if (pageItems) {
          s.pages.set(page, [latestCreatedTemplateId, ...pageItems])
        }
      }

      return { ...s, items, latestCreatedTemplateId, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { id } = action.payload

      const newItems = Object.fromEntries(
        Object.entries(s.items).filter(([key]) => key != String(id)),
      )

      const pages = s.pages
      s.pages.forEach((page, key) => {
        pages.set(
          key,
          page.filter((p) => p !== id),
        )
      })

      return { ...s, items: newItems, pages, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
