import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as organizationv1 from 'proto/iam/v1/organization_pb'
import * as userv1 from 'proto/iam/v1/user_pb'

import { ActionsUnion, createAction } from '../../helpers'

import { BookingUiType } from '../../../types/bookingui'
import { TransportOperatorUiType } from '../../../types/transportOperator'
import { DefaultAddress, UserConfig } from '../../../types/userui'

export const SET_USER_CONFIG = 'swift2/ui/SET_USER_CONFIG'
export const SET_USER_DEFAULT_ADDRESSES = 'swift2/ui/SET_USER_DEFAULT_ADDRESS'
export const EDIT_USER_DEFAULT_ADDRESS = 'swift2/ui/EDIT_USER_DEFAULT_ADDRESS'
export const SET_USER_FILTER_ORDER = 'swift2/ui/SET_USER_FILTER_ORDER'
export const SET_BOOKING_CONFIG = 'swift2/ui/SET_BOOKING_CONFIG'
export const CREATE_TRANSPORT_OPERATOR_REQ = 'swift2/ui/CREATE_TRANSPORT_OPERATOR_REQ'
export const CREATE_TRANSPORT_OPERATOR_RESP = 'swift2/ui/CREATE_TRANSPORT_OPERATOR_RESP'
export const CREATE_TRANSPORT_OPERATOR_ERR = 'swift2/ui/CREATE_TRANSPORT_OPERATOR_ERR'
export const EDIT_TRANSPORT_OPERATOR_REQ = 'swift2/ui/EDIT_TRANSPORT_OPERATOR_REQ'
export const EDIT_TRANSPORT_OPERATOR_RESP = 'swift2/ui/EDIT_TRANSPORT_OPERATOR_RESP'
export const EDIT_TRANSPORT_OPERATOR_ERR = 'swift2/ui/EDIT_TRANSPORT_OPERATOR_ERR'
export const DELETE_TRANSPORT_OPERATOR_REQ = 'swift2/ui/DELETE_TRANSPORT_OPERATOR_REQ'
export const DELETE_TRANSPORT_OPERATOR_RESP = 'swift2/ui/DELETE_TRANSPORT_OPERATOR_RESP'
export const DELETE_TRANSPORT_OPERATOR_ERR = 'swift2/ui/DELETE_TRANSPORT_OPERATOR_ERR'

export const LIST_TRANSPORT_OPERATOR_RESP = 'swift2/ui/LIST_TRANSPORT_OPERATOR_RESP'

export const Actions = {
  setUserConfig: (config?: UserConfig) => createAction(SET_USER_CONFIG, { config }),
  setUserDefaultAddresses: (defaultAddresses: DefaultAddress[], user?: userv1.User) =>
    createAction(SET_USER_DEFAULT_ADDRESSES, { defaultAddresses, user }),
  editUserDefaultAddress: (defaultAddress: DefaultAddress, user?: userv1.User) =>
    createAction(EDIT_USER_DEFAULT_ADDRESS, { defaultAddress, user }),
  setUserFilterOrder: (order: number[], user?: userv1.User) =>
    createAction(SET_USER_FILTER_ORDER, { order, user }),
  setBookingUiConfig: (booking: bookingv1.Booking, uiConfig: BookingUiType) =>
    createAction(SET_BOOKING_CONFIG, { booking, uiConfig }),
  listTransportOperatorsResp: (transportOperators: TransportOperatorUiType[]) =>
    createAction(LIST_TRANSPORT_OPERATOR_RESP, { transportOperators }),
  createTransportOperatorReq: (
    user: userv1.User,
    organization: organizationv1.Organization,
    transportOperator: TransportOperatorUiType,
  ) => createAction(CREATE_TRANSPORT_OPERATOR_REQ, { user, organization, transportOperator }),
  createTransportOperatorResp: (transportOperator: TransportOperatorUiType) =>
    createAction(CREATE_TRANSPORT_OPERATOR_RESP, { transportOperator }),
  createTransportOperatorErr: (err: Error) => createAction(CREATE_TRANSPORT_OPERATOR_ERR, { err }),

  editTransportOperatorReq: (
    user: userv1.User,
    organization: organizationv1.Organization,
    transportOperator: TransportOperatorUiType,
  ) => createAction(EDIT_TRANSPORT_OPERATOR_REQ, { user, organization, transportOperator }),
  editTransportOperatorResp: (transportOperator: TransportOperatorUiType) =>
    createAction(EDIT_TRANSPORT_OPERATOR_RESP, { transportOperator }),
  editTransportOperatorErr: (err: Error) => createAction(EDIT_TRANSPORT_OPERATOR_ERR, { err }),

  deleteTransportOperatorReq: (
    user: userv1.User,
    organization: organizationv1.Organization,
    id: string,
  ) => createAction(DELETE_TRANSPORT_OPERATOR_REQ, { user, organization, id }),
  deleteTransportOperatorResp: (id: string) => createAction(DELETE_TRANSPORT_OPERATOR_RESP, { id }),
  deleteTransportOperatorErr: (err: Error) => createAction(DELETE_TRANSPORT_OPERATOR_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
