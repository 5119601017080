import * as commonProvider from 'proto/common/provider_pb'
import * as articleTypev1 from 'proto/invoicing/v1/articletype_pb'

import { Pagination, defaultPageSize } from './query'

export interface ArticleTypeFilter {
  search?: string
  domesticVat?: boolean
  euVat?: boolean
  automation?: boolean
  category?: Array<articleTypev1.ArticleType.Category>
}

export interface ArticleTypePage {
  pagination: Pagination
  filter?: ArticleTypeFilter
  sorting?: articleTypev1.ArticleTypeSorting
}

export const getDefaultArticleTypePage = (): ArticleTypePage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new articleTypev1.ArticleTypeSorting(),
})

export type ParsingArticleTypePage = {
  skip: number
  limit: number
  filter?: string
  search?: string
  sorting?: string
}

export interface AccountingArticle {
  articleType: articleTypev1.ArticleType
  accountingNumber?: commonProvider.AccountingNumber
}

export const getDefaultAccountingArticle = (): AccountingArticle => ({
  articleType: new articleTypev1.ArticleType(),
})
