import * as grpcWeb from 'grpc-web'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { ProviderServicePromiseClient } from 'proto/invoicing/v1/provider_grpc_web_pb'
import * as providerv1 from 'proto/invoicing/v1/provider_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_PROVIDERS_REQ,
} from '../../store/invoicing/provider/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* listProviders(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.listProvidersReq>,
) {
  try {
    const { skip, limit } = action.payload

    const req = new providerv1.ListProvidersRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    const resp: providerv1.ListProvidersResponse = yield call(
      [client, client.listProviders],
      req,
      authMetadata(),
    )
    yield put(Actions.listProvidersResp(resp.getTotalCount(), resp.getProvidersList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listProvidersErr(err))
  }
}

export function* getProvider(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.getProviderReq>,
) {
  try {
    const { id } = action.payload
    const req = new providerv1.GetProviderRequest()
    req.setProviderId(id)

    const resp: providerv1.GetProviderResponse = yield call(
      [client, client.getProvider],
      req,
      authMetadata(),
    )
    const provider = resp.getProvider()
    if (!provider) {
      throw new Error('missing provider')
    }
    yield put(Actions.getProviderResp(provider))
  } catch (err) {
    if ((err as any).code && (err as any).code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getProviderResp(undefined))
    } else {
      if (err instanceof Error) yield put(Actions.getProviderErr(err))
    }
  }
}

export function* createProvider(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.createProviderReq>,
) {
  try {
    const { provider } = action.payload
    const req = new providerv1.CreateProviderRequest()
    req.setProvider(provider)
    const resp: providerv1.CreateProviderResponse = yield call(
      [client, client.createProvider],
      req,
      authMetadata(),
    )
    const newProvider = resp.getProvider()
    if (!newProvider) {
      throw new Error('missing provider')
    }
    yield put(Actions.createProviderResp(newProvider))
    yield put(
      NotificationActions.send({
        key: `provider-${newProvider.getProviderId()}`,
        kind: 'success',
        message: 'Provider Created',
        description: 'Provider ' + newProvider.getName() + ' was created successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createProviderErr(err))
  }
}

export function* editProvider(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.editProviderReq>,
) {
  try {
    const { provider } = action.payload
    const req = new providerv1.EditProviderRequest()
    req.setProvider(provider)
    const resp: providerv1.EditProviderResponse = yield call(
      [client, client.editProvider],
      req,
      authMetadata(),
    )
    const editedProvider = resp.getProvider()
    if (!editedProvider) {
      throw new Error('missing provider')
    }
    yield put(Actions.editProviderResp(editedProvider))
    yield put(
      NotificationActions.send({
        key: `provider-${editedProvider.getProviderId()}`,
        kind: 'success',
        message: 'Provider Updated',
        description: 'Provider ' + editedProvider.getName() + ' was updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editProviderErr(err))
  }
}

export function* deleteProvider(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.deleteProviderReq>,
) {
  try {
    const { id } = action.payload
    const req = new providerv1.DeleteProviderRequest()
    req.setProviderId(id)
    yield call([client, client.deleteProvider], req, authMetadata())

    yield put(
      NotificationActions.send({
        key: `provider-${id}`,
        kind: 'success',
        message: 'Provider Deleted',
        description: 'The Provider was deleted successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteProviderResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteProviderErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeEvery(LIST_PROVIDERS_REQ, listProviders, clients.provider)
  yield takeEvery(GET_REQ, getProvider, clients.provider)
  yield takeEvery(CREATE_REQ, createProvider, clients.provider)
  yield takeEvery(EDIT_REQ, editProvider, clients.provider)
  yield takeEvery(DELETE_REQ, deleteProvider, clients.provider)
}
