import * as branchv1 from 'proto/iam/v1/branch_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_BRANCHES_REQ = 'swift2/branch/LIST_BRANCHES_REQ'
export const LIST_BRANCHES_RESP = 'swift2/branch/LIST_BRANCHES_RESP'
export const LIST_BRANCHES_ERR = 'swift2/branch/LIST_BRANCHES_ERR'
export const GET_REQ = 'swift2/branch/GET_REQ'
export const GET_RESP = 'swift2/branch/GET_RESP'
export const GET_ERR = 'swift2/branch/GET_ERR'
export const CREATE_REQ = 'swift2/branch/CREATE_REQ'
export const CREATE_RESP = 'swift2/branch/CREATE_RESP'
export const CREATE_ERR = 'swift2/branch/CREATE_ERR'
export const EDIT_REQ = 'swift2/branch/EDIT_REQ'
export const EDIT_RESP = 'swift2/branch/EDIT_RESP'
export const EDIT_ERR = 'swift2/branch/EDIT_ERR'
export const DELETE_REQ = 'swift2/branch/DELETE_REQ'
export const DELETE_RESP = 'swift2/branch/DELETE_RESP'
export const DELETE_ERR = 'swift2/branch/DELETE_ERR'
export const SET_CURRENT_BRANCH = 'swift2/branch/SET_CURRENT_BRANCH'

export const Actions = {
  listBranchesReq: (limit: number, skip: number) =>
    createAction(LIST_BRANCHES_REQ, { limit, skip }),
  listBranchesResp: (count: number, branches: branchv1.Branch[]) =>
    createAction(LIST_BRANCHES_RESP, { count, branches }),
  listBranchesErr: (err: Error) => createAction(LIST_BRANCHES_ERR, { err }),

  getBranchReq: (id: number) => createAction(GET_REQ, { id }),
  getBranchResp: (branch?: branchv1.Branch) => createAction(GET_RESP, { branch }),
  getBranchErr: (err: Error) => createAction(GET_ERR, { err }),

  createBranchReq: (branch: branchv1.Branch) => createAction(CREATE_REQ, { branch }),
  createBranchResp: (branch: branchv1.Branch) => createAction(CREATE_RESP, { branch }),
  createBranchErr: (err: Error) => createAction(CREATE_ERR, { err }),

  editBranchReq: (branch: branchv1.Branch) => createAction(EDIT_REQ, { branch }),
  editBranchResp: (branch: branchv1.Branch) => createAction(EDIT_RESP, { branch }),
  editBranchErr: (err: Error) => createAction(EDIT_ERR, { err }),

  deleteBranchReq: (id: number) => createAction(DELETE_REQ, { id }),
  deleteBranchResp: (id: number) => createAction(DELETE_RESP, { id }),
  deleteBranchErr: (err: Error) => createAction(DELETE_ERR, { err }),

  setCurrentBranch: (branch?: branchv1.Branch) => createAction(SET_CURRENT_BRANCH, { branch }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
