import * as shipmentv1 from 'proto/shipment/v1/shipment_pb'

import { Pagination, defaultPageSize } from './query'

export interface ShipmentFilter {
  transportOperator?: Array<string>
  search?: string
}

export type ShipmentPage = {
  pagination: Pagination
  filter: ShipmentFilter
  sorting: shipmentv1.ShipmentSorting
}

export type ParsingShipmentPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
  filterID?: number
}

export const getDefaultShipmentPage = (): ShipmentPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new shipmentv1.ShipmentSorting(),
})
