import * as userv1 from 'proto/iam/v1/user_pb'

import { RootState } from '../../store/reducer'

import { StoryItem, StoryMessage } from '../../components/Story/types'

import * as actions from './actions'

export interface State {
  readonly refs: { bookingRef: string; orderRef: string; shipmentRef: string }
  readonly storyItems: StoryItem[]
  readonly users: { [key: string]: userv1.User }
  readonly isFetching: boolean
  readonly isFetchingUsers: boolean
  readonly err: Array<'USER' | 'STORY_ITEM' | undefined>
}

const initialState: State = {
  refs: { bookingRef: '', orderRef: '', shipmentRef: '' },
  storyItems: [],
  users: {},
  isFetching: false,
  isFetchingUsers: false,
  err: [],
}

export const getItems = (state: RootState) => state.story.storyItems

export const getPinnedMessages = (state: RootState): StoryMessage[] =>
  state.story.storyItems
    .filter((item) => item.tag === 'MESSAGE')
    .map((item) => item as StoryMessage)
    .filter((item) => item.message.getPinned())

export const getUsers = (state: RootState) => state.story.users

export const getCurrentReferences = (state: RootState) => state.story.refs

export const getIsFetching = (state: RootState) =>
  state.story.isFetching || state.story.isFetchingUsers

export const getErrorMsg = (state: RootState) => state.story.err

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.SET_REFS: {
      const { bookingRef, orderRef, shipmentRef } = action.payload
      return { ...s, refs: { bookingRef, orderRef, shipmentRef } }
    }
    case actions.SET_STORY_ITEMS: {
      const { storyItems } = action.payload
      return {
        ...s,
        storyItems,
        isFetching: false,
        err: s.err.filter((errType) => errType !== 'STORY_ITEM'),
      }
    }
    case actions.SET_STORY_ITEM_ERR: {
      return {
        ...s,
        storyItems: [],
        isFetching: false,
        err: [...s.err, 'STORY_ITEM'],
      }
    }
    case actions.UPDATE_MESSAGES: {
      return { ...s, isFetching: true }
    }
    case actions.UPDATE_DOCUMENTS: {
      return { ...s, isFetching: true }
    }
    case actions.SET_IS_FETCHING: {
      const { isFetching } = action.payload
      return { ...s, isFetching }
    }
    case actions.GET_ALL_ITEMS: {
      return { ...s, storyItems: [], users: {}, isFetching: true }
    }
    case actions.GET_STORY_USERS_REQ: {
      return { ...s, isFetchingUsers: true }
    }
    case actions.GET_STORY_USERS_RESP: {
      const { users } = action.payload
      return {
        ...s,
        users: { ...s.users, ...users },
        isFetchingUsers: false,
        err: s.err.filter((errType) => errType !== 'USER'),
      }
    }
    case actions.GET_STORY_USERS_ERR: {
      return {
        ...s,
        users: {},
        isFetchingUsers: false,
        err: [...s.err, 'USER'],
      }
    }
    default:
      return s
  }
}
