import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { fromSurchargeProto, toSurchargeProto } from 'shared/mappers/surcharge.mapper'

import { ContractServicePromiseClient } from 'proto/contract/v1/contract_grpc_web_pb'
import {
  CreateSurchargeRequest,
  CreateSurchargeResponse,
  ListSurchargesRequest,
  ListSurchargesResponse,
  UpdateSurchargeRequest,
} from 'proto/contract/v1/contract_pb'

import { Actions as NotificationActions } from '../../store/notification/actions'
import {
  Actions,
  CREATE_SURCHARGE,
  LIST_SURCHARGES,
  UPDATE_SURCHARGE,
} from 'store/surcharge/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof Actions.listSurcharges>,
) {
  yield put(Actions.listSurchargesResponse([]))

  try {
    const req = new ListSurchargesRequest()
    if (action.payload.page) {
      req.setFilter(action.payload.page.filter)
    }

    const resp: ListSurchargesResponse = yield call(
      [client, client.listSurcharges],
      req,
      authMetadata(),
    )

    const mappedSurcharge = resp
      .getSurchargesList()
      .map((surcharge) => fromSurchargeProto(surcharge))
    yield put(Actions.listSurchargesResponse(mappedSurcharge ?? []))
  } catch (err: any) {
    yield put(Actions.surchargeError(err))
  }
}

export function* create(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof Actions.createSurcharge>,
) {
  try {
    const { surcharge } = action.payload
    const req = new CreateSurchargeRequest()
    const surchargeProto = toSurchargeProto(surcharge)
    req.setSurcharge(surchargeProto)
    const resp: CreateSurchargeResponse = yield call(
      [client, client.createSurcharge],
      req,
      authMetadata(),
    )
    const surchargeResponse = resp.getSurcharge()
    if (!surchargeResponse) {
      throw new Error('missing surcharge')
    }

    yield put(Actions.createSurchargeResponse(fromSurchargeProto(surchargeResponse)))

    yield put(
      NotificationActions.send({
        key: `surcharge-${surchargeResponse.getSurchargeId()}`,
        kind: 'success',
        message: 'Surcharge created',
        description: 'The surcharge has been created.',
        dismissAfter: 4500,
      }),
    )
    action.payload.onSuccess()
  } catch (err: any) {
    yield put(Actions.createSurchargeError(err))
  }
}

export function* update(
  client: ContractServicePromiseClient,
  action: ReturnType<typeof Actions.updateSurcharge>,
) {
  try {
    const { surcharge } = action.payload
    const req = new UpdateSurchargeRequest()
    const surchargeProto = toSurchargeProto(surcharge)
    req.setSurcharge(surchargeProto)
    const resp: UpdateSurchargeRequest = yield call(
      [client, client.updateSurcharge],
      req,
      authMetadata(),
    )
    const surchargeResponse = resp.getSurcharge()
    if (!surchargeResponse) {
      throw new Error('missing surcharge')
    }

    yield put(Actions.updateSurchargeResponse(fromSurchargeProto(surchargeResponse)))

    yield put(
      NotificationActions.send({
        key: `surcharge-updated`,
        kind: 'success',
        message: 'Surcharge updated',
        description: 'The surcharge has been updated.',
        dismissAfter: 4500,
      }),
    )
    action.payload.onSuccess()
  } catch (err: any) {
    yield put(Actions.updateSurchargeError(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_SURCHARGES, list, clients.contract)
  yield takeEvery(CREATE_SURCHARGE, create, clients.contract)
  yield takeEvery(UPDATE_SURCHARGE, update, clients.contract)
}
