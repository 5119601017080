import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { CurrencyServicePromiseClient } from 'proto/currency/v1/currency_grpc_web_pb'
import * as currencyv1 from 'proto/currency/v1/currency_pb'

import {
  Actions,
  CONVERT_CURRENCY_REQ,
  CREATE_RATE_REQ,
  GET_CURRENCY_RATE_REQ,
  LIST_RATES_REQ,
} from '../../store/currency/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* createRate(
  client: CurrencyServicePromiseClient,
  action: ReturnType<typeof Actions.createRateReq>,
) {
  try {
    const { rate, dailyInternalRate } = action.payload
    const req = new currencyv1.CreateRateRequest()
    req.setCurrencyRate(rate)
    req.setDailyInternalRate(dailyInternalRate)

    const resp: currencyv1.CreateRateResponse = yield call(
      [client, client.createRate],
      req,
      authMetadata(),
    )
    const newRate = resp.getCurrencyRate()
    if (!newRate) {
      throw new Error('missing new rate')
    }
    yield put(Actions.createRateResp(newRate))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createRateErr(err))
  }
}

export function* listCurrencyRates(
  client: CurrencyServicePromiseClient,
  action: ReturnType<typeof Actions.listCurrencyRatesReq>,
) {
  try {
    const { page } = action.payload

    const req = new currencyv1.ListCurrencyRatesRequest()
    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }
    if (page.filter) {
      const filter = new currencyv1.CurrencyFilter()
      if (page.filter.incoming_currency && page.filter.incoming_currency.length > 0) {
        filter.setFromCurrencyList(page.filter.incoming_currency)
      }
      if (page.filter.lookupDate) {
        filter.setLookupDate(page.filter.lookupDate)
      }
      if (page.filter.sourceType && page.filter.sourceType.length > 0) {
        filter.setSourceTypeList(page.filter.sourceType)
      }
      req.setFilter(filter)
    }
    if (page.sorting) {
      const sorting = new currencyv1.CurrencySorting()
      sorting.setField(page.sorting.getField())
      sorting.setOrdering(page.sorting.getOrdering())
      req.setSorting(sorting)
    }

    const resp: currencyv1.ListCurrencyRatesResponse = yield call(
      [client, client.listCurrencyRates],
      req,
      authMetadata(),
    )
    yield put(Actions.ListCurrencyRatesResp(resp.getTotalCount(), resp.getCurrencyRateList(), page))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listCurrencyRatesErr(err))
  }
}

export function* getCurrencyRate(
  client: CurrencyServicePromiseClient,
  action: ReturnType<typeof Actions.getCurrencyRateReq>,
) {
  try {
    const { currencyFrom, currencyTo, sourceType, lookupDate } = action.payload

    const req = new currencyv1.GetCurrencyRateRequest()
    req.setFromCurrency(currencyFrom)
    req.setToCurrency(currencyTo)
    req.setSourceType(sourceType)
    req.setLookupDate(lookupDate)

    const resp: currencyv1.GetCurrencyRateResponse = yield call(
      [client, client.getCurrencyRate],
      req,
      authMetadata(),
    )

    const currencyRate = resp.getCurrencyRate()
    if (!currencyRate) {
      throw new Error('missing currency rate')
    }
    yield put(Actions.getCurrencyRateResp(currencyRate))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.getCurrencyRateErr(err))
  }
}

export function* convert(
  client: CurrencyServicePromiseClient,
  action: ReturnType<typeof Actions.convertCurrencyReq>,
) {
  try {
    const { conv } = action.payload

    const req = new currencyv1.ConvertCurrencyRequest()
    req.setConvertCurrencyList(conv)

    const resp: currencyv1.ConvertCurrencyResponse = yield call(
      [client, client.convertCurrency],
      req,
      authMetadata(),
    )

    const newConverted = resp.getConvertCurrencyList()
    if (!newConverted) {
      throw new Error('missing converted currency')
    }
    const errors: string[] = []
    newConverted.map(
      (c) =>
        c.getValidationErrorsList().length > 0 &&
        errors.push(c.getValidationErrorsList().toString()),
    )
    if (errors.length > 0) {
      throw new Error(errors.toString())
    }
    yield put(Actions.convertCurrencyResp(newConverted))
  } catch (err) {
    // NOTE: Error value is hidden here.
    const { type } = action.payload
    yield put(Actions.convertCurrencyErr(new Error('could not convert currency'), type))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(CREATE_RATE_REQ, createRate, clients.currency)
  yield takeLatest(LIST_RATES_REQ, listCurrencyRates, clients.currency)
  yield takeLatest(GET_CURRENCY_RATE_REQ, getCurrencyRate, clients.currency)
  yield takeEvery(CONVERT_CURRENCY_REQ, convert, clients.currency)
}
