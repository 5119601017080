import * as commonProvider from 'proto/common/provider_pb'
import * as articleTypev1 from 'proto/invoicing/v1/articletype_pb'

import { RootState } from '../../../store/reducer'

import * as articleTypes from '../../../types/articleType'

import * as actions from './actions'

export interface State {
  readonly pages: Map<articleTypes.ArticleTypePage, Array<number>>
  readonly items: { [key: string]: articleTypev1.ArticleType }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly accountingItems: { [key: string]: commonProvider.AccountingNumber }
  readonly accountingErr?: Error
  readonly isFetchingAccounting: boolean
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  accountingItems: {},
  accountingErr: undefined,
  isFetchingAccounting: false,
}

// Article type
export const getPageItems = (state: RootState, page: articleTypes.ArticleTypePage) => {
  const items = state.invoicing.articleType.items
  const pageItems = state.invoicing.articleType.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<articleTypev1.ArticleType>)
}
export const getItems = (state: RootState) => {
  return Object.keys(state.invoicing.articleType.items).map(
    (id) => state.invoicing.articleType.items[id],
  )
}
export const getCount = (state: RootState) => state.invoicing.articleType.count
export const getErrors = (state: RootState) => state.invoicing.articleType.err
export const getIsFetchingArticleType = (state: RootState) => state.invoicing.articleType.isFetching

// Accounting number
export const getAccountingItems = (state: RootState) => {
  return Object.keys(state.invoicing.articleType.accountingItems).map(
    (id) => state.invoicing.articleType.accountingItems[id],
  )
}
export const getAccountingErrors = (state: RootState) => state.invoicing.articleType.accountingErr
export const getIsFetchingAccountingNumbers = (state: RootState) =>
  state.invoicing.articleType.isFetchingAccounting

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_ARTICLE_TYPES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.LIST_ARTICLE_TYPES_RESP: {
      const { count, articleTypes, page } = action.payload

      const newItems = Object.fromEntries(articleTypes.map((a) => [a.getArticleNo(), a]))
      const items = { ...s.items, ...newItems }

      const pageItems = articleTypes.map((a) => a.getArticleNo())
      const pages = s.pages

      pages.set(page, pageItems)

      return { ...s, count, err: undefined, isFetching: false, items, pages }
    }
    case actions.LIST_ARTICLE_TYPES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }
    case actions.LIST_ACCOUNTING_NUMBERS_REQ: {
      return { ...s, accountingErr: undefined, isFetchingAccounting: true }
    }
    case actions.LIST_ACCOUNTING_NUMBERS_RESP: {
      const { accountingNumbers } = action.payload
      const accountingItems = Object.fromEntries(
        accountingNumbers.map((a) => [a.getArticleNumber(), a]),
      )
      return { ...s, isFetchingAccounting: false, accountingItems }
    }
    case actions.LIST_ACCOUNTING_NUMBERS_ERR: {
      const { err } = action.payload
      return { ...s, accountingErr: err, isFetchingAccounting: false }
    }

    case actions.CREATE_ARTICLE_TYPE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.CREATE_ARTICLE_TYPE_RESP: {
      const { articleType, page } = action.payload
      const items = {
        ...s.items,
        [articleType.getArticleNo()]: articleType,
      }
      if (page) {
        const pageItems = s.pages.get(page)
        if (pageItems) {
          s.pages.set(page, [articleType.getArticleNo(), ...pageItems])
        }
      }
      return { ...s, items, isFetching: false }
    }
    case actions.CREATE_ARTICLE_TYPE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }
    case actions.SET_ACCOUNTING_NUMBER_REQ: {
      return { ...s, accountingErr: undefined, isFetchingAccounting: true }
    }
    case actions.SET_ACCOUNTING_NUMBER_RESP: {
      const { accountingNumber } = action.payload
      const accountingItems = {
        ...s.accountingItems,
        [accountingNumber.getArticleNumber()]: accountingNumber,
      }
      return { ...s, accountingItems, isFetchingAccounting: false }
    }
    case actions.SET_ACCOUNTING_NUMBER_ERR: {
      const { err } = action.payload
      return { ...s, accountingErr: err, isFetchingAccounting: false }
    }

    case actions.UPDATE_ARTICLE_TYPE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.UPDATE_ARTICLE_TYPE_RESP: {
      const { articleType } = action.payload
      const items = {
        ...s.items,
        [articleType.getArticleNo()]: articleType,
      }
      return { ...s, items, isFetching: false }
    }
    case actions.UPDATE_ARTICLE_TYPE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_ARTICLE_TYPE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.DELETE_ARTICLE_TYPE_RESP: {
      const { articleNo } = action.payload
      const filteredItems = Object.entries(s.items).filter((a) => a[1].getArticleNo() !== articleNo)
      const items = Object.fromEntries(filteredItems)

      const pages = s.pages
      s.pages.forEach((page, key) => {
        pages.set(
          key,
          page.filter((p) => p !== articleNo),
        )
      })

      return { ...s, items, isFetching: false, pages }
    }
    case actions.DELETE_ARTICLE_TYPE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }
    case actions.DELETE_ACCOUNTING_NUMBER_REQ: {
      return { ...s, accountingErr: undefined, isFetchingAccounting: true }
    }
    case actions.DELETE_ACCOUNTING_NUMBER_RESP: {
      const { articleNo } = action.payload
      const filteredItems = Object.entries(s.accountingItems).filter(
        (a) => a[1].getArticleNumber() !== articleNo,
      )
      const accountingItems = Object.fromEntries(filteredItems)
      return { ...s, accountingItems, isFetchingAccounting: false }
    }
    case actions.DELETE_ACCOUNTING_NUMBER_ERR: {
      const { err } = action.payload
      return { ...s, accountingErr: err, isFetchingAccounting: false }
    }
    default: {
      return s
    }
  }
}
