import * as userv1 from 'proto/iam/v1/user_pb'
import { User } from 'proto/iam/v1/user_pb'

import { RootState } from '../../../store/reducer'

import * as userTypes from '../../../types/user'

import * as actions from './actions'

export interface State {
  readonly pages: Map<userTypes.UserPage, Array<string>>
  readonly items: { [key: string]: userv1.User }
  readonly count: number
  readonly err?: Error
  readonly writingErr?: Error
  readonly uploadErr?: Error
  readonly isListingUsers: boolean
  readonly isFetching: boolean
  readonly currentUser?: userv1.User
  readonly allRoles: Array<userv1.User.Role>
}

const initialState: State = {
  pages: new Map(),

  items: {},
  count: 0,
  err: undefined,
  writingErr: undefined,
  uploadErr: undefined,
  isListingUsers: false,
  isFetching: true,
  // TODO: Move current user to auth module.
  currentUser: undefined,
  allRoles: [],
}

export const getItems = (state: RootState) => {
  return Object.keys(state.iam.user.items).map((id) => state.iam.user.items[id])
}
export const getItemsById = (state: RootState) => {
  return state.iam.user.items
}
export const getUserById = (state: RootState, id?: string) => {
  if (!id) {
    return
  }
  return state.iam.user.items[id]
}

export const getPageItems = (state: RootState, page: userTypes.UserPage) => {
  const items = state.iam.user.items
  const pageItems = state.iam.user.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<User>)
}

export const getCount = (state: RootState) => state.iam.user.count
export const getErr = (state: RootState) => state.iam.user.err
export const getWritingErr = (state: RootState) => state.iam.user.writingErr
export const getUploadErr = (state: RootState) => state.iam.user.uploadErr
export const getIsFetching = (state: RootState) => state.iam.user.isFetching
export const getIsListingFetching = (state: RootState) => state.iam.user.isListingUsers
export const getCurrentUser = (state: RootState) => state.iam.user.currentUser
export const getProfilePictureCurrentUser = (state: RootState) =>
  state.iam.user.currentUser?.getImg()
export const getAllRoles = (state: RootState) => state.iam.user.allRoles

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_USERS_REQ: {
      return { ...s, err: undefined, isListingUsers: true }
    }

    case actions.LIST_USERS_RESP: {
      const { count, users, page } = action.payload
      const newItems = users.reduce<{ [key: string]: userv1.User }>((map, u) => {
        map[u.getUserId()] = u
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      const pageItems = users.map((u) => u.getUserId())

      const pages = s.pages

      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, pageItems)
      return { ...s, items: items, count, isListingUsers: false }
    }

    case actions.LIST_USERS_ERR: {
      const { err } = action.payload
      return { ...s, err, isListingUsers: false }
    }

    case actions.LIST_PUBLIC_USERS_REQ: {
      return { ...s, err: undefined, isListingUsers: true }
    }

    case actions.LIST_PUBLIC_USERS_RESP: {
      const { count, users } = action.payload
      const newItems = users.reduce<{ [key: string]: userv1.User }>((map, u) => {
        map[u.getUserId()] = u
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items: items, count, isListingUsers: false }
    }

    case actions.LIST_PUBLIC_USERS_ERR: {
      const { err } = action.payload
      return { ...s, err, isListingUsers: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { user } = action.payload
      if (!user) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [user.getUserId()]: user,
      }
      return { ...s, items: items }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, writingErr: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { user } = action.payload
      if (!user) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [user.getUserId()]: user,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, writingErr: err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, writingErr: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { user } = action.payload
      if (!user) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [user.getUserId()]: user,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, writingErr: err, isFetching: false }
    }

    case actions.SET_CURRENT_USER: {
      const { user } = action.payload
      const allRoles = user ? user.getRolesList() : []
      return { ...s, currentUser: user, allRoles, isFetching: false }
    }

    case actions.SET_ROLES: {
      const { roles } = action.payload
      const { currentUser, allRoles } = s
      const filteredRoles = roles.filter((r) => allRoles.indexOf(r) >= 0)
      if (currentUser) {
        currentUser.setRolesList(filteredRoles)
        return { ...s, currentUser: userv1.User.deserializeBinary(currentUser.serializeBinary()) }
      }
      return s
    }

    case actions.UPLOAD_PROFILE_PICTURE_ERR: {
      const { err } = action.payload
      return { ...s, uploadErr: err, isFetching: false }
    }

    default:
      return s
  }
}
