import * as bookingtemplatev1 from 'proto/booking/v1/bookingtemplate_pb'

import { Pagination, defaultPageSize } from './query'

export type TemplatePage = {
  pagination: Pagination
  sorting: bookingtemplatev1.TemplateSorting
}

export const getDefaultTemplatePage = (): TemplatePage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  sorting: new bookingtemplatev1.TemplateSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
export type ParsingTemplatePage = {
  skip: number
  limit: number
  sorting?: string
}
