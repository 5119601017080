import * as productv1 from 'proto/order/v1/product_pb'

import * as productTypes from '../../../types/product'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly pages: Map<productTypes.ProductPage, Array<string>>
  readonly items: { [key: string]: productv1.Product }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.order.product.items).map((sku) => state.order.product.items[sku])
}
export const getItemsById = (state: RootState) => {
  return state.order.product.items
}
export const getCount = (state: RootState) => state.order.product.count
export const getPageItems = (state: RootState, page: productTypes.ProductPage) => {
  const items = state.order.product.items
  const pageItems = state.order.product.pages.get(page) || []
  return pageItems.map((sku) => items[sku], [] as Array<productv1.Product>)
}
export const getErr = (state: RootState) => state.order.product.err
export const getIsFetching = (state: RootState) => state.order.product.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, products, page } = action.payload
      const newItems = products.reduce<{ [key: string]: productv1.Product }>((map, it) => {
        map[it.getSku()] = it
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      const pageItems = products.map((p) => p.getSku())
      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { product } = action.payload
      const items = {
        ...s.items,
        [product.getSku()]: product,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { product } = action.payload
      const items = {
        ...s.items,
        [product.getSku()]: product,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_RESP: {
      const { product } = action.payload
      const items = {
        ...s.items,
        [product.getSku()]: product,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
