import * as supplierv1 from 'proto/booking/v1/supplier_pb'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: supplierv1.Supplier }
  readonly configs: { [key: number]: supplierv1.SupplierConfig }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  configs: {},
  count: 0,
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.booking.supplier.items).map((id) => state.booking.supplier.items[id])
}
export const getErr = (state: RootState) => state.booking.supplier.err
export const getIsFetching = (state: RootState) => state.booking.supplier.isFetching

export const getItemById = (state: RootState, supId: number) => {
  return getItems(state).find((sup) => sup.getSupplierId() === supId)
}

export const getConfigs = (state: RootState) => {
  return Object.keys(state.booking.supplier.configs).map(
    (orgId) => state.booking.supplier.configs[Number(orgId)],
  )
}
export const getConfigErr = (state: RootState) => state.booking.supplier.err
export const getConfigIsFetching = (state: RootState) => state.booking.supplier.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, suppliers } = action.payload
      const newItems = suppliers.reduce((items, s) => {
        return { ...items, [s.getSupplierId()]: s }
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items, count, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { supplier } = action.payload
      const items = {
        ...s.items,
        [supplier.getSupplierId()]: supplier,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { supplier } = action.payload
      const items = { ...s.items, [supplier.getSupplierId()]: supplier } as {
        [key: string]: supplierv1.Supplier
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { supplierID } = action.payload
      const items = Object.keys(s.items).reduce<{ [key: string]: supplierv1.Supplier }>(
        (items, key) => {
          if (s.items[key].getSupplierId() !== supplierID) {
            items[key] = s.items[key]
          }
          return items
        },
        {},
      )

      return { ...s, items, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetching: false }
    }

    case actions.CREATE_SERVICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_SERVICE_RESP: {
      const { service } = action.payload
      const items = s.items
      items[service.getSupplierId()].setServicesList([
        ...items[service.getSupplierId()].getServicesList(),
        service,
      ])
      return { ...s, items, err: undefined, isFetching: false }
    }

    case actions.CREATE_SERVICE_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetching: false }
    }

    case actions.EDIT_SERVICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_SERVICE_RESP: {
      const { service } = action.payload
      const items = s.items

      items[service.getSupplierId()].setServicesList([
        ...items[service.getSupplierId()].getServicesList().map((ser) => {
          return ser.getServiceId() !== service.getServiceId() ? ser : service
        }),
      ])

      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_SERVICE_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetching: false }
    }

    case actions.DELETE_SERVICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_SERVICE_RESP: {
      const { serviceID } = action.payload

      const items = s.items
      Object.keys(items).forEach((key) => {
        items[key].setServicesList(
          items[key].getServicesList().filter((s) => s.getServiceId() !== serviceID),
        )
      })

      return { ...s, items, isFetching: false }
    }

    case actions.DELETE_SERVICE_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetching: false }
    }

    case actions.LIST_CONFIGS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_CONFIGS_RESP: {
      const { count, configs } = action.payload
      return { ...s, count, configs, isFetching: false }
    }

    case actions.LIST_CONFIGS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_CONFIG_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_CONFIG_RESP: {
      const { supplierConfig } = action.payload
      const configs = {
        ...s.configs,
        [Number(supplierConfig.getOwner()?.getOrganizationId())]: supplierConfig,
      }
      return { ...s, configs, isFetching: false }
    }

    case actions.SET_CONFIG_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_SUPPLIER_WITH_SERVICES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_SUPPLIER_WITH_SERVICES_RESP: {
      const { supplier } = action.payload
      const items = {
        ...s.items,
        [supplier.getSupplierId()]: supplier,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_SUPPLIER_WITH_SERVICES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
