import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { NominationServicePromiseClient } from 'proto/booking/v1/nomination_grpc_web_pb'
import * as nominationv1 from 'proto/booking/v1/nomination_pb'

import {
  Actions,
  EDIT_REQ,
  LIST_REQ,
  REVALIDATE_NOMINATIONS_REQ,
  SET_PRICING_REQ,
  UPDATE_BOOKING_DATA_REQ,
} from '../../store/booking/nomination/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

import { GRPCClients } from '../clients'

export function* list(
  client: NominationServicePromiseClient,
  action: ReturnType<typeof Actions.listReq>,
) {
  try {
    const { bookingIDs, fetch } = action.payload
    if (!fetch) {
      return
    }
    const req = new nominationv1.ListNominationsRequest()
    req.setBookingIdsList(bookingIDs)
    const resp: nominationv1.ListNominationsResponse = yield call(
      [client, client.listNominations],
      req,
      authMetadata(),
    )
    const nominations = resp.getNominationsList()
    yield put(Actions.listResp(nominations, bookingIDs))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listErr(err))
  }
}

export function* edit(
  client: NominationServicePromiseClient,
  action: ReturnType<typeof Actions.editReq>,
) {
  try {
    const { nomination } = action.payload
    const req = new nominationv1.EditNominationRequest()
    req.setNomination(nomination)
    const resp: nominationv1.EditNominationResponse = yield call(
      [client, client.editNomination],
      req,
      authMetadata(),
    )
    const editedNomination = resp.getNomination()
    if (!editedNomination) {
      throw new Error('missing nomination')
    }
    yield put(Actions.editResp(editedNomination))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editErr(err))
  }
}

export function* setNominationPrice(
  client: NominationServicePromiseClient,
  action: ReturnType<typeof Actions.setNominationPriceReq>,
) {
  try {
    const { nominationId, pricingConfig } = action.payload
    const req = new nominationv1.SetNominationPriceRequest()
    req.setNominationId(nominationId)
    req.setPricingConfig(pricingConfig)
    const resp: nominationv1.EditNominationResponse = yield call(
      [client, client.setNominationPrice],
      req,
      authMetadata(),
    )

    const editedNomination = resp.getNomination()
    if (editedNomination) {
      yield put(
        NotificationActions.send({
          key: `nomination-${editedNomination.getNominationId()}`,
          kind: 'success',
          message: 'Pricing Saved',
          description: 'Pricing was saved successfully',
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('missing nomination')
    }
    yield put(Actions.setNominationPriceResp(editedNomination))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.setNominationPriceErr(err))
  }
}

export function* revalidateNominations(
  client: NominationServicePromiseClient,
  action: ReturnType<typeof Actions.revalidateBookingReq>,
) {
  try {
    const { bookingId } = action.payload
    const req = new nominationv1.RevalidateNominationsRequest()
    req.setBookingid(bookingId)
    const resp: nominationv1.RevalidateNominationsResponse = yield call(
      [client, client.revalidateNominations],
      req,
      authMetadata(),
    )
    const responseBooking = resp.getNominationsList()
    if (!responseBooking) {
      throw new Error('missing booking')
    }
    const nominations = resp.getNominationsList()
    yield put(Actions.revalidateBookingResp(nominations))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.revalidateBookingErr(err))
  }
}

export function* updateBookingData(
  client: NominationServicePromiseClient,
  action: ReturnType<typeof Actions.updateBookingDataReq>,
) {
  try {
    const { nominationId, transportRef } = action.payload
    const req = new nominationv1.UpdateBookingDataRequest()
    req.setNominationId(nominationId)
    req.setTransportRef(transportRef)
    const resp: nominationv1.UpdateBookingDataResponse = yield call(
      [client, client.updateBookingData],
      req,
      authMetadata(),
    )
    const nomination = resp.getNomination()
    if (!nomination) {
      throw new Error('missing nominations')
    }
    yield put(Actions.updateBookingDataResp(nomination))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.updateBookingDataErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.nomination)
  yield takeLatest(EDIT_REQ, edit, clients.nomination)
  yield takeLatest(SET_PRICING_REQ, setNominationPrice, clients.nomination)
  yield takeEvery(REVALIDATE_NOMINATIONS_REQ, revalidateNominations, clients.nomination)
  yield takeEvery(UPDATE_BOOKING_DATA_REQ, updateBookingData, clients.nomination)
}
