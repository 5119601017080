import * as currencyv1 from 'proto/currency/v1/currency_pb'

import { RootState } from '../../store/reducer'

import { timestampToMoment } from '../../helpers/timestamp'

import * as currencyTypes from '../../types/currency'

import * as actions from './actions'

export interface State {
  readonly pages: Map<currencyTypes.CurrencyPage, Array<number>>
  readonly currencyRates: ReadonlyArray<currencyv1.CurrencyRate>
  readonly items: ReadonlyArray<currencyv1.ConvertCurrency>
  readonly err?: Error
  readonly count: number
  readonly articleErr?: Error
  readonly costErr?: Error
  readonly priceErr?: Error
  readonly unknownErr?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  pages: new Map(),
  currencyRates: [],
  count: 0,
  items: [],
  err: undefined,
  isFetching: false,
}

export const getCurrencyRates = (state: RootState) => {
  return state.currency.currencyRates
}

export const getRatesCount = (state: RootState) => state.currency.count

export const getPageCurrencyRates = (state: RootState, page: currencyTypes.CurrencyPage) => {
  const rates = state.currency.currencyRates.reduce<{ [key: string]: currencyv1.CurrencyRate }>(
    (map, r) => {
      map[r.getCurrencyRateId()] = r
      return map
    },
    {},
  )
  const pageItems = state.currency.pages.get(page) || []
  return pageItems.map((id) => rates[id], [] as Array<currencyv1.CurrencyRate>)
}

export const getConvertedListByType = (state: RootState, type: currencyv1.ConvertCurrency.Type) => {
  return state.currency.items.filter((i) => i.getType() === type)
}

export const getConvertedListByRef = (
  state: RootState,
  bookingRef: string,
  type: currencyv1.ConvertCurrency.Type,
) => {
  return state.currency.items.filter(
    (i) => i.getBookingRef() === bookingRef && i.getType() === type,
  )
}

export const getConvertedByAmount = (
  state: RootState,
  bookingRef: string,
  type: currencyv1.ConvertCurrency.Type,
  amount: number,
) =>
  state.currency.items.filter(
    (i) => i.getBookingRef() === bookingRef && i.getType() === type && i.getAmount() === amount,
  )

export const getErr = (state: RootState) => state.currency.err
export const getArticleErr = (state: RootState) => state.currency.articleErr
export const getCostErr = (state: RootState) => state.currency.costErr
export const getPriceErr = (state: RootState) => state.currency.priceErr

export const getIsFetching = (state: RootState) => state.currency.isFetching
export const getLatestCurrencyRate = (
  state: RootState,
  fromCurrency: string,
  toCurrency?: string,
  source?: currencyv1.CurrencyRate.SourceType,
) => {
  if (toCurrency == undefined || toCurrency == '' || source == undefined) {
    return undefined
  }
  const filteredCurrencyRates = state.currency.currencyRates.filter(
    (r) =>
      r.getFromCurrency() === fromCurrency &&
      r.getToCurrency() === toCurrency &&
      r.getSourceType() === source,
  )
  const currencyRate = filteredCurrencyRates.reduce<currencyv1.CurrencyRate | undefined>(
    (latestRate, rate) => {
      if (!latestRate) {
        return rate
      }
      const latestDate = timestampToMoment(latestRate.getLookupDate())
      const currentRateDate = timestampToMoment(rate.getLookupDate())
      if (!latestRate || latestDate.isBefore(currentRateDate)) {
        return rate
      }
      return latestRate
    },
    undefined,
  )
  return currencyRate
}

function filterLists(
  existingList: ReadonlyArray<currencyv1.ConvertCurrency>,
  newList: ReadonlyArray<currencyv1.ConvertCurrency>,
): ReadonlyArray<currencyv1.ConvertCurrency> {
  const result: currencyv1.ConvertCurrency[] = [...newList]
  newList.filter((a) => a.getType() === currencyv1.ConvertCurrency.Type.ARTICLE).length === 0 &&
    existingList
      .filter((a) => a.getType() === currencyv1.ConvertCurrency.Type.ARTICLE)
      .forEach((m) => result.push(m))
  newList.filter((c) => c.getType() === currencyv1.ConvertCurrency.Type.COST).length === 0 &&
    existingList
      .filter((c) => c.getType() === currencyv1.ConvertCurrency.Type.COST)
      .forEach((m) => result.push(m))
  newList.filter((p) => p.getType() === currencyv1.ConvertCurrency.Type.PRICE).length === 0 &&
    existingList
      .filter((p) => p.getType() === currencyv1.ConvertCurrency.Type.PRICE)
      .forEach((m) => result.push(m))
  newList.filter((p) => p.getType() === currencyv1.ConvertCurrency.Type.UNKNOWN).length === 0 &&
    existingList
      .filter((p) => p.getType() === currencyv1.ConvertCurrency.Type.UNKNOWN)
      .forEach((m) => result.push(m))

  return result
}

function filterRatesList(
  existingList: ReadonlyArray<currencyv1.CurrencyRate>,
  newList: ReadonlyArray<currencyv1.CurrencyRate>,
): ReadonlyArray<currencyv1.CurrencyRate> {
  const result: currencyv1.CurrencyRate[] = [...newList]
  existingList.forEach(
    (e) => !newList.some((n) => n.getCurrencyRateId() === e.getCurrencyRateId()) && result.push(e),
  )
  return result
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.CREATE_RATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.CREATE_RATE_RESP: {
      const { rate } = action.payload
      const allRates = filterRatesList(s.currencyRates, [rate])

      return { ...s, currencyRates: allRates, count: s.count + 1, isFetching: false }
    }
    case actions.CREATE_RATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.LIST_RATES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.LIST_RATES_RESP: {
      const { count, rates, page } = action.payload

      const allRates = filterRatesList(s.currencyRates, rates)

      const pages = s.pages
      const pageItems = rates.map((r) => r.getCurrencyRateId())
      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)

      return { ...s, currencyRates: allRates, count, isFetching: false, pages }
    }
    case actions.LIST_RATES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_CURRENCY_RATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.GET_CURRENCY_RATE_RESP: {
      const { currencyRate } = action.payload

      const newCurrencyRates = s.currencyRates.reduce<Array<currencyv1.CurrencyRate>>(
        (rates, r) => {
          if (
            r.getFromCurrency() !== currencyRate.getFromCurrency() &&
            r.getSourceType() !== currencyRate.getSourceType() &&
            r.getLookupDate() !== currencyRate.getLookupDate()
          ) {
            rates.push(r)
          }
          return rates
        },
        [],
      )
      newCurrencyRates.push(currencyRate)
      return { ...s, currencyRates: newCurrencyRates, isFetching: false }
    }
    case actions.GET_CURRENCY_RATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CONVERT_CURRENCY_REQ: {
      const { type } = action.payload
      const newState = setTypeError(s, type, undefined)
      return { ...newState, err: undefined, isFetching: true }
    }
    case actions.CONVERT_CURRENCY_RESP: {
      const { conv } = action.payload
      const cl = filterLists(s.items, conv)
      return { ...s, items: cl, isFetching: false }
    }
    case actions.CONVERT_CURRENCY_ERR: {
      const { err, type } = action.payload
      const newState = setTypeError(s, type, err)
      return { ...newState, err, isFetching: false }
    }
    case actions.RESET_CONVERT_CURRENCY_ERR: {
      const { type } = action.payload
      const newState = setTypeError(s, type, undefined)
      return { ...newState }
    }
    default:
      return s
  }
}

const setTypeError = (
  s: State,
  type: currencyv1.ConvertCurrency.Type,
  err: Error | undefined,
): State => {
  switch (type) {
    case currencyv1.ConvertCurrency.Type.ARTICLE:
      return { ...s, articleErr: err }
    case currencyv1.ConvertCurrency.Type.COST:
      return { ...s, costErr: err }
    case currencyv1.ConvertCurrency.Type.PRICE:
      return { ...s, priceErr: err }
    case currencyv1.ConvertCurrency.Type.UNKNOWN:
      return { ...s, unknownErr: err }
  }
  return { ...s }
}
