import * as commonOwner from 'proto/common/owner_pb'
import * as messagev1 from 'proto/messaging/v1/messaging_pb'

import { ActionsUnion, createAction } from '../helpers'

export const LIST_REQUEST = 'swift2/message/LIST_REQUEST'
export const LIST_RESPONSE = 'swift2/message/LIST_RESPONSE'
export const LIST_ERROR = 'swift2/message/LIST_ERROR'

export const SET_PINNED_MESSAGE_REQUEST = 'swift2/message/SET_PINNED_MESSAGE_REQUEST'
export const SET_PINNED_MESSAGE_RESPONSE = 'swift2/message/SET_PINNED_MESSAGE_RESPONSE'
export const SET_PINNED_MESSAGE_ERROR = 'swift2/message/SET_PINNED_MESSAGE_ERROR'

export const SEND_MESSAGE = 'swift2/message/SEND_MESSAGE'
export const SEND_MESSAGE_SUCCESS = 'swift2/message/SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_ERROR = 'swift2/message/SEND_MESSAGE_ERROR'

export const Actions = {
  list: (orderRef: string, bookingRef: string, shipmentRef: string) =>
    createAction(LIST_REQUEST, { orderRef, bookingRef, shipmentRef }),
  listResp: (messages: ReadonlyArray<messagev1.Message>, count: number) =>
    createAction(LIST_RESPONSE, { messages, count }),
  listErr: (error: Error) => createAction(LIST_ERROR, { error }),

  setPinnedReq: (id: number, isPinned: boolean) =>
    createAction(SET_PINNED_MESSAGE_REQUEST, { id, isPinned }),
  setPinnedResp: (message: messagev1.Message) =>
    createAction(SET_PINNED_MESSAGE_RESPONSE, { message }),
  setPinnedErr: (error: Error) => createAction(SET_PINNED_MESSAGE_ERROR, { error }),

  sendMessage: (
    text: string,
    orderRef: string,
    bookingRef: string,
    shipmentRef: string,
    relatedOwner: commonOwner.Owner,
    internal: boolean,
  ) =>
    createAction(SEND_MESSAGE, { text, orderRef, bookingRef, shipmentRef, relatedOwner, internal }),
  sendMessageSuccess: (message: messagev1.Message) =>
    createAction(SEND_MESSAGE_SUCCESS, { message }),
  sendMessageError: (error: Error) => createAction(SEND_MESSAGE_ERROR, { error }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
