import * as bookingv1 from 'proto/booking/v1/booking_pb'

import { RootState } from '../../../store/reducer'

import * as bookingTypes from '../../../types/booking'

import * as actions from './actions'

export interface State {
  readonly pages: Map<bookingTypes.BookingPage, Array<number>>
  readonly items: { [key: string]: bookingv1.Booking }
  readonly bookingEvents: { [key: string]: Array<bookingv1.BookingEvent> }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly isUpdatingTransportRef: boolean
  readonly isFetchingEvents: boolean
  readonly stats?: bookingv1.BookingStats
}

const initialState: State = {
  pages: new Map(),
  items: {},
  bookingEvents: {},
  count: 0,
  err: undefined,
  isFetching: false,
  isUpdatingTransportRef: false,
  isFetchingEvents: false,
  stats: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.booking.booking.items).map((id) => state.booking.booking.items[id])
}

export const getPageItems = (state: RootState, page: bookingTypes.BookingPage) => {
  const items = state.booking.booking.items
  const pageItems = state.booking.booking.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<bookingv1.Booking>)
}

export const getItemsById = (state: RootState) => {
  return state.booking.booking.items
}

export const getOwnerOnBooking = (state: RootState, bookingRef: string) => {
  const booking = Object.values(state.booking.booking.items).find(
    (item) => item.getBookingRef() === bookingRef,
  )
  return booking && booking.getOwner()
}

export const getItemByRef = (state: RootState, ref: string) => {
  return Object.keys(state.booking.booking.items)
    .map((id) => state.booking.booking.items[id])
    .find((booking) => (booking ? booking.getBookingRef() === ref : false))
}

export const getItemsByRefs = (state: RootState, refs?: string[]) => {
  if (!refs) {
    return []
  }
  const bookings = Object.keys(state.booking.booking.items).map(
    (id) => state.booking.booking.items[id],
  )
  return bookings.filter((b) =>
    b && refs.length ? refs.some((bRef) => bRef === b.getBookingRef()) : false,
  )
}

export const getCount = (state: RootState) => state.booking.booking.count

export const getStats = (state: RootState) => {
  return state.booking.booking.stats
}

export const getErr = (state: RootState) => state.booking.booking.err
export const getIsFetching = (state: RootState) => state.booking.booking.isFetching

export const getIsUpdatingTransportRef = (state: RootState) =>
  state.booking.booking.isUpdatingTransportRef

export const getEvents = (state: RootState, bookingRef: string) =>
  state.booking.booking.bookingEvents[bookingRef] || []

export const getLinkedBooking = (state: RootState, bookingRef?: string) =>
  bookingRef ? state.booking.booking.items[bookingRef] : undefined

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, bookings, page } = action.payload

      const newItems = bookings.reduce<{ [key: string]: bookingv1.Booking }>((map, r) => {
        map[r.getBookingId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      const pageItems = bookings.map((b) => b.getBookingId())

      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { booking } = action.payload
      if (!booking) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_EVENTS_REQ: {
      return { ...s, isFetchingEvents: true }
    }

    case actions.GET_EVENTS_RESP: {
      const { events, bookingRef } = action.payload
      const bookingEvents = {
        ...s.bookingEvents,
        [bookingRef]: events,
      }

      return { ...s, bookingEvents, isFetchingEvents: false }
    }

    case actions.GET_EVENTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetchingEvents: false }
    }

    case actions.GET_STATS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_STATS_RESP: {
      const { stats } = action.payload
      // NOTE: Replace all stats for now.
      return { ...s, stats, isFetching: false }
    }

    case actions.GET_STATS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_BOOKING_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_BOOKING_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_BOOKING_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.QUOTE_BOOKING_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.QUOTE_BOOKING_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.QUOTE_BOOKING_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_QUOTES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_QUOTES_RESP: {
      return { ...s, isFetching: false }
    }

    case actions.GET_QUOTES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.ACCEPT_BOOKING_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.ACCEPT_BOOKING_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.ACCEPT_BOOKING_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_ESTIMATES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_ESTIMATES_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_ESTIMATES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_TRANSPORT_REF_REQ: {
      return { ...s, err: undefined, isUpdatingTransportRef: true }
    }

    case actions.UPDATE_TRANSPORT_REF_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isUpdatingTransportRef: false }
    }

    case actions.UPDATE_TRANSPORT_REF_ERR: {
      const { err } = action.payload
      return { ...s, err, isUpdatingTransportRef: false }
    }

    case actions.UPDATE_STATUS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_STATUS_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_STATUS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_INTERNAL_NOTE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_INTERNAL_NOTE_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_INTERNAL_NOTE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { bookingID } = action.payload
      const items = Object.keys(s.items).reduce<{ [key: string]: bookingv1.Booking }>(
        (items, key) => {
          if (s.items[key].getBookingId() !== bookingID) {
            items[key] = s.items[key]
          }
          return items
        },
        {},
      )

      const pages = s.pages
      s.pages.forEach((page, key) => {
        pages.set(
          key,
          page.filter((p) => p !== bookingID),
        )
      })
      return { ...s, items, pages, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_BOOKING_ITEMS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_BOOKING_ITEMS_RESP: {
      const { booking } = action.payload
      const items: { [key: string]: bookingv1.Booking } = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_BOOKING_ITEMS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_REQUIREMENTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_REQUIREMENTS_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_REQUIREMENTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_BOOKING_PRICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_BOOKING_PRICE_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.SET_BOOKING_PRICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_TRANSPORT_OPERATOR_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_TRANSPORT_OPERATOR_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_TRANSPORT_OPERATOR_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_ACTION_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_ACTION_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.SET_ACTION_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_FINANCIAL_MONTH_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_FINANCIAL_MONTH_RESP: {
      const { booking } = action.payload
      const items = {
        ...s.items,
        [booking.getBookingId()]: booking,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.SET_FINANCIAL_MONTH_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
