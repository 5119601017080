import * as productv1 from 'proto/order/v1/product_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as productTypes from '../../../types/product'

export const LIST_REQ = 'swift2/product/LIST_REQ'
export const LIST_RESP = 'swift2/product/LIST_RESP'
export const LIST_ERR = 'swift2/product/LIST_ERR'
export const GET_REQ = 'swift2/product/GET_REQ'
export const GET_RESP = 'swift2/product/GET_RESP'
export const GET_ERR = 'swift2/product/GET_ERR'
export const CREATE_REQ = 'swift2/product/CREATE_REQ'
export const CREATE_RESP = 'swift2/product/CREATE_RESP'
export const CREATE_ERR = 'swift2/product/CREATE_ERR'
export const UPDATE_REQ = 'swift2/product/UPDATE_REQ'
export const UPDATE_RESP = 'swift2/product/UPDATE_RESP'
export const UPDATE_ERR = 'swift2/product/UPDATE_ERR'

export const Actions = {
  listProductsReq: (page: productTypes.ProductPage) => createAction(LIST_REQ, { page }),
  listProductsResp: (
    count: number,
    products: productv1.Product[],
    page: productTypes.ProductPage,
  ) => createAction(LIST_RESP, { count, products, page }),
  listProductsErr: (err: Error) => createAction(LIST_ERR, { err }),
  getProductReq: (sku: string) => createAction(GET_REQ, { sku }),
  getProductResp: (product: productv1.Product) => createAction(GET_RESP, { product }),
  getProductErr: (err: Error) => createAction(GET_ERR, { err }),
  createProductReq: (product: productv1.Product) => createAction(CREATE_REQ, { product }),
  createProductResp: (product: productv1.Product) => createAction(CREATE_RESP, { product }),
  createProductErr: (err: Error) => createAction(CREATE_ERR, { err }),
  updateProductReq: (product: productv1.Product) => createAction(UPDATE_REQ, { product }),
  updateProductResp: (product: productv1.Product) => createAction(UPDATE_RESP, { product }),
  updateProductErr: (err: Error) => createAction(UPDATE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
